// Firebase ist V9, verwendet im V8 Modus. 
// Umstellung auf V9 erst möglich, wenn vuefire es kann
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Vue from 'vue'
import {firestorePlugin} from 'vuefire'
Vue.use(firestorePlugin);


const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyBCpDFIAUZ_4yqGqAo6CwSggr25ctbsAkM",
  authDomain: "crowdalerts-ef6b5.firebaseapp.com",
  databaseURL: "https://crowdalerts-ef6b5.firebaseio.com",///
  projectId: "crowdalerts-ef6b5",
  storageBucket: "crowdalerts-ef6b5.appspot.com",
  messagingSenderId: "1089539192022",
  appId: "1:1089539192022:web:ca7cbcfe4be325881e0577"
});
console.log("firebase.js")
export const db = firebaseApp.firestore();


