

<template>
  <div class="sign">
    {{usertext}}  
  </div>
</template>

<script>

import Sign from "../pdfsign.js";

export default {
  name: 'sign',
    data: () => ({
    usertext: "Bitte warten",
    triggered: false
  }),
  mounted(){
      this.bill = new Sign()
      this.bill.setData("Mülleimer voll?", "Liebe Mitbuerger,\nbitte scannen Sie den Code um uns ueber den vollen Muelleimer zu benachrichtigen.\nDanke, Ihre Verwaltung", "bla fasel")
      var doc = this.bill.createpdf();
      doc.save("Schild.pdf")
  },
}

</script>
