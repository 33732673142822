<!-- Achtung: be Änderungen muss AGBversion in constants.json hochgezählt werden -->
<template>
  <div class="about">
    <h1>Nutzungsbedingungen</h1>
    Version: {{getConstant("agbversion")}} [{{getConstant("agbdate")}}]
      <h2> Geltungsbereich  </h2>
        <p>(1) Die vorliegenden Nutzungsbedingungen enthalten die zwischen Ihnen und uns, <b>crowdalerts.net</b>, Mozartstr. 14, 73269 Hochdorf, Deutschland, geltenden Geschäftsbedingungen.</p>
      <h2> Vertragsschluss; Fälligkeit; Lieferbeschränkungen  </h2>
        <p>(1) Der Vertrags wird mit dem Click auf den Button "Kostenpflichtig bestellen" geschlossen.</p>
        <p>(2) Der Vertrag wird nach dem Ablauf der Vertragsdauer automatisch verlängert und eine neue Rechnung wird ausgestellt. Die automatische Verlängerung kann jederzeit abgestellt werden.
        <p>(3) Der Kaufpreis wird mit der Rechnungsstellung fällig.</p>
        <p>(4) Der Service kann in allen Mitgliedsländern der Europäischen Union genutzt werden.</p>
    <p></p>
    <h2>Widerrufsrecht</h2>
      <p>Der Vertrag kann innerhalb von 14 Tagen widerrufen werden.  </p>
    <p></p>
    <h2>Einsatzgebiete  </h2>
      <p>(1) Der vorgesehene Einsatzzweck von <b>crowdalerts.net</b> ist das Übertragen und Sammeln von nicht sicherheitskritischen Informationen. Wie z.bB</p>
        <li>a. Schadensmeldungen (z.b. „Schaden auf Spielplatz“)</li>
        <li>b. Zustandsmeldungen (z.b. „Papier am Drucker leer“)</li>
        <li>c. Sammel von Informationen (z.b. Essensvorbestellung in der Mensa)</li>
        <li>d. Jegliches Feedback (z.b. Fragen und Anmerkungen)</li>
        <p></p>

      <p>(2) <b>crowdalerts.net</b> darf <b>auf keinen Fall</b> zum Melden von sicherheitskritischen Informationen verwendet werden, wie z.B.</p>
        <li>a. Notrufe aller Art</li>
    <p></p>
    <h2>Unsere Haftung</h2>
      <p>(1) <b>crowdalerts.net</b> liefert über 99,5% der Meldungen aus, jedoch kann die Auslieferung nicht garantiert werden. </p>
      <p>(2) Die Dauer zwischen Absenden und Auslieferung der Meldung ist in der Regel </p>
        <li>a. Bei Nutzung der <b>crowdalerts.net</b> Liste unter 1 min</li>
        <li>b. Bei Messengerdiensten unter 3 min</li>
        <li>c. Bei email unter 5 min</li>
      <p>Jedoch kann die Dauer nicht garantiert werden.</p>
      <p>(3) <b>crowdalerts.net</b> haftet nicht für jegliche Schäden, die durch Nichtauslieferung der Meldung oder durch verzögertes Ausliefern entstanden sind.</p>
      <p>(4) <b>crowdalerts.net</b> haftet nicht für jeglichen Datenverlust.</p>
    <p></p>

    

  </div>
</template>

<!-- 
TODO     <h2>Ausschluss nicht gute Sitten</h2>
-->


<script>
import { getConstant } from "../constants.js";
export default {
  methods: {
    getConstant(x) {
      return getConstant(x)
    },
  }
}
</script>
