<template>
<div>
  <v-snackbar v-model="snackbar" :timeout="5000" top :color=snackbarcolor>{{snackbartext}} <v-btn icon @click="snackbar=false"><v-icon>mdi-close</v-icon></v-btn></v-snackbar>
  <v-toolbar flat color="white" id="v-step-2" >
    <v-toolbar-title>Alarmdesign<v-icon>mdi-alarm-light</v-icon></v-toolbar-title>
  </v-toolbar>
      <v-card elevation="2" outlined>
      <v-card-text>
        <v-switch v-model="userdb.design.owndesign" label="Eigenes Design verwenden"  @change="save()"></v-switch>
      </v-card-text>
      <v-card-title v-if="userdb.design.owndesign">Titelzeile </v-card-title>
      <v-card-text v-if="userdb.design.owndesign">
        <v-row >
          <v-col>
            <v-text-field  label="Text in Titelzeile" prepend-icon="mdi-tablet-android" type="text" @change="save()" v-model="userdb.design.titletext"></v-text-field>
            <v-text-field  label="Link bei Klick auf Titeltext (z.b. https://ihrefirma.de)" prepend-icon="mdi-tablet-android" type="text" @change="save()" v-model="userdb.design.titlelink"></v-text-field>
            Textfarbe:<br>
            <v-btn v-for="cc in colors" :key="cc.col+'titcol'" @click="userdb.design.titlecolor=cc.col;save()" target="_blank" small class="ma-1" fab :color="cc.col" ></v-btn>        
            <v-text-field label="Farbcode" prepend-icon="mdi-tablet-android" type="text" @change="save()" v-model="userdb.design.titlecolor"></v-text-field>
            Hintergrundfarbe:<br>
            <v-btn v-for="ccc in colors" :key="ccc.col+'backcol'" @click="userdb.design.backcolor=ccc.col;save()" target="_blank" small class="ma-1" fab :color="ccc.col" ></v-btn>        
            <v-text-field label="Farbcode" prepend-icon="mdi-tablet-android" type="text" @change="save()" v-model="userdb.design.backcolor"></v-text-field>
          </v-col>
          <v-col>
            Vorschau:
            <v-card elevation="2" outlined>
                <a :href="userdb.design.titlelink" style="text-decoration: none">
                    <v-toolbar :color="userdb.design.backcolor" dense dark >
                    <v-toolbar-title ><b :style="userdb.design.titlecolorcss">{{userdb.design.titletext}}</b></v-toolbar-title>
                    <v-spacer></v-spacer>
                    </v-toolbar>
                </a>
                <v-card-text>
                    <v-form>
                    <div class="text--primary">
                        Beispieltext für einen Alarm
                    </div>
                    <v-toolbar flat color="white">
                        <v-spacer></v-spacer>
                        <v-btn color="primary" dark class="ma-2">Absenden</v-btn>
                    </v-toolbar>
                    </v-form>
                    <v-row justify="center">
                        <v-btn text class="mr-2" href="https://crowdalerts.net/">{{ "delivered by crowdalerts.net" }}</v-btn>
                    </v-row>
                </v-card-text>
            </v-card>
          </v-col>


      </v-row>


      </v-card-text>
      </v-card>
      <v-toolbar flat color="white">
        <v-spacer></v-spacer>
        <v-btn color="grey" dark class="ma-2" @click="$router.push('alerts')">Zurück zur Übersicht</v-btn>
      </v-toolbar>

  </div>
</template>

<script>
import { db } from "../firebase";
import { accountUUID } from "../user.js";
import { interal_site_login_check } from "../user.js";

export default {
  name: 'alerts',
  data() {
    return {
      userdb: {design:{}},
      snackbar: false,
      snackbartext: "",
      snackbarcolor: "",
      colors: [
        {col:"#F44336" },
        {col:"#E91E63" },
        {col:"#9C27B0" },
        {col:"#673AB7" },
        {col:"#3F51B5" },
        {col:"#2196F3" },
        {col:"#03A9F4" },
        {col:"#00BCD4" },
        {col:"#009688" },
        {col:"#4CAF50" },
        {col:"#8BC34A" },
        {col:"#CDDC39" },
        {col:"#FFEB3B" },
        {col:"#FFC107" },
        {col:"#FF9800" },
        {col:"#FF5722" },
        {col:"#795548" },
        {col:"#607D8B" },
        {col:"#9E9E9E" },
        {col:"#FFFFFF" },
        {col:"#000000" },
      ],
    }
  },
  firestore() {
    return {
      userdb: db.collection('userbase').doc(accountUUID()),
    }
  },
  created(){
    interal_site_login_check()
  },
  methods: {
    save() {
//      console.log("sss"+this.userdb.design.titlelink)
      this.userdb.design.titlecolorcss = "color:"+this.userdb.design.titlecolor
      if (this.userdb.design.titlelink!=undefined && !this.userdb.design.titlelink.startsWith("http")) {
        this.userdb.design.titlelink = "http://"+this.userdb.design.titlelink
      }
      db.collection('userbase').doc(accountUUID()).update("design", this.userdb.design)
      db.collection('publicdesign').doc(accountUUID()).set({design: this.userdb.design})
    },
  }
}

</script>

<style scoped>
  .mauspointer { cursor: pointer }
</style>
