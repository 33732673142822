<template>
  <div class="verify">
    {{usertext}}  
  </div>
</template>

<script>
  // a = einfaches Scan und Alarm
  import { db } from "../firebase";

  export default {
    name: 'verify',
        data: () => ({
        usertext: "Bitte warten",
        triggered: false
    }),
    created(){
      // path: '/verify/:userid/:targetid/:hash',
      db.collection('ztmp_verify').add({
        useruuid: this.$route.params.userid,
        targetid: this.$route.params.targetid,
        hash: this.$route.params.hash, 
        date: Date.now()}).then((pa) => { 
        this.usertext = "Adresse verifiziert.\n Es dauert einen Moment bis dies auf der Webseite ersichtlich ist"
      })
    },
  }
</script>
