<template>
<div>
  <v-snackbar v-model="snackbar" :timeout="5000" top :color=snackbarcolor>{{snackbartext}} <v-btn icon @click="snackbar=false"><v-icon>mdi-close</v-icon></v-btn></v-snackbar>
  <v-toolbar flat color="white">
    <v-toolbar-title>crowdalerts.net Listen<v-icon>mdi-playlist-check</v-icon></v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn color="primary" dark class="mb-2" @click="newList()">Neue Liste</v-btn>

    <template v-slot:extension>
      <v-tabs v-model="currentTab">
        <v-tabs-slider color="yellow"></v-tabs-slider>
        <v-tab v-for="item in users_targets" :key="item.id"  @change="loadList(item.id)">  
          {{ item.targettitle }} 
        </v-tab>
      </v-tabs>
    </template>
  </v-toolbar>

  <v-tabs-items v-model="currentTab">
    <v-tab-item v-for="item in users_targets" :key="item.id">
      <v-data-table :headers="headers" :items="currentList" class="elevation-3" sort-by="date" sort-desc>
        <template v-slot:item="props" >

          <tr >
            <td>{{ props.item.alerttitle }}</td>
            <td>{{ props.item.alerttargettext }} </td>
            <td v-if="props.item.dates" >{{ props.item.dates.length}} Alarme seit {{dateToStringFLEX(props.item.datedeleted, true)}}
              <v-sparkline :value="getSparklineData(props.item)" :labels="getSparklineLabels(props.item)" auto-draw  smooth="10" label-size="12" show-labels> </v-sparkline>
            </td>
            <td v-if="props.item.inputfields">
              <div v-for="item in props.item.inputfields" :key="item.id">
                  {{item.name}}: {{item.answer}}
              </div>
            </td>
            <td v-if="props.item.dates">
              Letzter Alarm: <br> {{ dateToStringFLEX(props.item.date) }} 
            </td>
            <td v-if="props.item.inputfields">
              {{ dateToStringFLEX(props.item.date) }} 
            </td>
            <td v-if="props.item.inputfields">
              <v-btn @click="deletealert(props.item.id, props.item.dates)" target="_blank" text ><v-icon>mdi-trash-can</v-icon></v-btn>
            </td>
            <td v-if="props.item.dates">
              <v-btn @click="deletealertsoft(props.item.id, props.item.dates)" target="_blank" text ><v-icon>mdi-trash-can</v-icon></v-btn>
            </td>
          </tr>

        </template>
      </v-data-table>
    </v-tab-item>
  </v-tabs-items>
  <v-btn v-if="this.users_targets.length>0" id="targets-edit" @click="editlist()" target="_blank" text ><v-icon>mdi-pencil</v-icon>Liste umbenennen</v-btn>
  <v-btn v-if="this.users_targets.length>0" @click="deletelist()" target="_blank" text ><v-icon>mdi-trash-can</v-icon>Liste löschen</v-btn>
  <v-btn v-if="this.users_targets.length>0" @click="downloadexcel()" target="_blank" text ><v-icon>mdi-file-excel</v-icon>Liste als Excel exportieren</v-btn>




   </div>
</template>

<script>
import { db } from "../firebase";
import { accountUUID } from "../user.js";
import router from '../router'
import { interal_site_login_check } from "../user.js";
import { getIconFromType } from "../helpers.js";
import { isVerified } from "../helpers.js";
import { dateToStringFLEX, dateToStringFLEXcompact, dateToStringDDMMYYYYTIME, dateToStringExcel, excelGetColName} from "../helpers.js";
import { getAccountValues } from "../constants.js";
import * as XLSX from 'xlsx-js-style';

export default {
  name: 'alerts',
  data: () => ({
    users_targets: [],
//    items:["fsdf", "23434gdfgf"],
    currentItem: "",
    currentList: [],
    currentListID: 0,
    currentTab: 0,
    userdb: {},
    snackbar: false,
    snackbartext: "",
    snackbarcolor: "",
    headers: [
      { text: 'Name'},
      { text: 'Hinweise', sortable: false },
      { text: 'Informationen', sortable: false },
      { text: 'Datum', value: 'date', align: 'left'},
      { text: '', sortable: false }
    ],
  }),
  firestore: {
    users_targets: db.collection('userbase').doc("null").collection('targets'),
    currentList: db.collection('userbase').doc("null").collection('targets').where('deleted', '==', false),
    userdb: db.collection('userbase').doc(accountUUID()),
  },
  components: {
//      QrcodeVue,
  },
  created(){
    interal_site_login_check()
  },
  beforeMount(){
    this.$bind('users_targets',db.collection('userbase').doc(accountUUID()).collection('targets').where('type','==','crowdalertslist').where('deleted', '==', false)).then(snapshot => { // Liste über alle Passenden, also einer
      let params = this.$route.params     
      if (params.targetid!=null) {
        snapshot.forEach(crowli => {
          if (crowli.id==params.targetid) {
            this.loadList(params.targetid)
            this.currentTab = snapshot.indexOf(crowli)
          }
        });
      }
      else {
        if (snapshot.length >0) {
          this.loadList(snapshot[0].id)
        }
        else {
          this.snackbartext = "Es ist noch keine Liste angelegt"
          this.snackbarcolor = "info"
          this.snackbar = true
        }
        
      }
       
    })
    this.$bind('userdb',db.collection('userbase').doc(accountUUID()))
  },
  methods: {
    loadList(id) {
      this.currentListID = id
      this.$bind('currentList',db.collection('userbase').doc(accountUUID()).collection('targets').doc(id).collection("alerts").where('deleted', '==', false))
      // TODO headers generieren
    },
    loadListFirst() {
      console.log(this.users_targets.length)
      if (this.users_targets.length>0) {
//        console.log(this.users_targets[0].id)
        this.currentTab = 0
        this.loadList(this.users_targets[0].id)
      }
    },
    newList() {
      var maxnumb = getAccountValues(this.userdb.account.accounttype, "maxTargets")

      db.collection('userbase').doc(accountUUID()).collection('targets').where('deleted', '==', false).get().then((snapshot) => {
        if (snapshot.size >= maxnumb) {
  //        console.log("too much alerts")
          this.snackbartext = "Maximalzahl der Ziele im Vertrag "+getAccountValues(this.userdb.account.accounttype, "name")+" erreicht. Bitte ändern Sie den Vertrag in EINSTELLUNGEN"
          this.snackbarcolor = "error"
          this.snackbar = true
        }
        else {
          db.collection('statistics').doc(accountUUID()).update("usedTargets", this.users_targets.length+1)
          db.collection('userbase').doc(accountUUID()).collection('targets').add({type: "new", deleted: false, type: "crowdalertslist"}).then(
            (res) => {      
              router.push({ name: 'targetedit', params: { targetId: res.id,  aim: "newlist"} })
            });
        }
      })
    },
    blablub() {
        console.log("adsdasdsd")
        let museums = db.collectionGroup('alerts').where('triggercode', '==', '1cKX');
        museums.get().then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            console.log("fsdf")
            console.log(doc.id, ' => ', doc.data());

            var usertargets = doc.ref().parent()

            console.log(usertargets);
            
            var ta = doc.data().targets
            ta.forEach(function(item, index) {

                  // Hier alle adressen finden und in email collection eintagen
                  console.log(item, index);
      //            var thetarget = usertargets.doc(item)
      //            console.log(thetarget.adress)
            });
          });
        });
    },
    gettargetName(id) {
      var ret = "id"
       this.users_targets.forEach(function(item) {
         if (item.id == id) {
           ret=item.targettitle
         }
        });
      return ret //users_targets.doc(id).adress""
    },
    gettargetAdress(id) {
      var ret = "id"
       this.users_targets.forEach(function(item) {
         if (item.id == id) {
           ret=item.adress
         }
        });
      return ret //users_targets.doc(id).adress""
    },
    gettargetAdressIcon(id) {
      var ret = "id"
       this.users_targets.forEach(function(item) {
         if (item.id == id) {
            ret = getIconFromType(item.type)
         }
        });
      return ret //users_targets.doc(id).adress""
    },
    deletealert(alertid)  {
      db.collection('userbase').doc(accountUUID()).collection('targets').doc(this.currentListID).collection("alerts").doc(alertid).delete()
    },
    deletealertsoft(alertid)  {
      db.collection('userbase').doc(accountUUID()).collection('targets').doc(this.currentListID).collection("alerts").doc(alertid).update({deleted: true, dates: [], datedeleted:  Date.now()})
    },
    dateToStringFLEX(x, seit) {
      return dateToStringFLEX(x, seit)
    },
    inputfieldsToHTML(infis) {
      var ret=""
      infis.forEach(infiel => {
          ret = ret + infiel.name+ ": " + infiel.answer+"<br>"
      });
     return ret
    },
    editlist() {
      
      router.push({ name: 'targetedit', params: { targetId: this.currentListID ,  aim: "edit" } })

    },
    deletelist()  {
//      console.log(this.currentList.length)
      if (this.currentList.length>0) {
        this.snackbartext = "Listen können nur gelöscht werden, wenn sie leer sind. Bitte Liste zuerst leeren"
        this.snackbarcolor = "error"
        this.snackbar = true

      }
      else {
        db.collection('statistics').doc(accountUUID()).update("usedTargets", this.users_targets.length-1)
        db.collection('userbase').doc(accountUUID()).collection('targets').doc(this.currentListID).update({deleted: true, deletedate: Date.now()})
        this.loadListFirst()
      }

      
    },
    getNumberTimeslots(aler) {
      if (aler.dates.length<20) {
        return 20
      }
      if (aler.dates.length<50) {
        return aler.dates.length
      }
      return 50
    },
    getSparklineData(aler) {
      const timeslots = this.getNumberTimeslots(aler)
      var timeframe = Date.now() - aler.datedeleted
      var timeframeslot = timeframe/timeslots
      var spdat = []
      var count =0
      for (let step = 0; step < timeslots; step++) {
        var timestart = aler.datedeleted + step*timeframeslot
        var timeend = aler.datedeleted + (step+1)*timeframeslot
        
        aler.dates.forEach(element => {
          if (element>timestart && element<=timeend) {
            count++
          }          
        });
        spdat.push(count)
      }
      console.log(spdat)
      return spdat
    },
    getSparklineLabels(aler) {
      const timeslots = this.getNumberTimeslots(aler)
      var spdat = []
      spdat.push(" ")
      spdat.push(dateToStringFLEXcompact(aler.datedeleted))
      for (let step = 0; step < timeslots-4; step++) {
        spdat.push(" ")
      }
      spdat.push("jetzt")
      spdat.push(" ")
      return spdat
    },


    downloadexcel() {
      var collums = []
      collums.push("void für index = Spalte")
      collums.push("Alarmname")
      collums.push("Alarmhinweis")
      collums.push("Alarmdatum")
      this.currentList.forEach(ala => {
        if (ala.dates != undefined) { // nur wenn es alarme mit zähler gibt "Anzahl Meldungen" dazu
          if (collums.indexOf("Anzahl Meldungen") == -1) { 
            collums.push("Anzahl Meldungen")
          }
        }
      })
      this.currentList.forEach(ala => { // alle inputfieldspalten dazu
        if (ala.inputfields != undefined) {
          ala.inputfields.forEach(infi => {
            if (collums.indexOf(infi.name) == -1) {
              collums.push(infi.name)
            }
          })
        }
      })

      var dataWS = XLSX.utils.json_to_sheet([]) 
      // HEADER
      collums.forEach(function callback(colu, index) {
        if (index>0) {
          var cell = excelGetColName(index)+"1"
          XLSX.utils.sheet_add_aoa(dataWS, [[colu]], {origin: cell});
          // https://gitbrent.github.io/xlsx-js-style/
          dataWS[cell].s = {font: {bold: true,}, };

        }
      })

      // Daten
      this.currentList.forEach(function callback(ala, index) {
        var linenr = index +2
        XLSX.utils.sheet_add_aoa(dataWS, [[ala.alerttitle]], {origin: "A"+linenr});
        XLSX.utils.sheet_add_aoa(dataWS, [[ala.alerttargettext]], {origin: "B"+linenr});
        // TODO datum exceltauglich formatieren
        XLSX.utils.sheet_add_aoa(dataWS, [[dateToStringExcel(ala.date)]], {origin: "C"+linenr});
        if (ala.dates != undefined) { 
          XLSX.utils.sheet_add_aoa(dataWS, [[ala.dates.length]], {origin: excelGetColName(collums.indexOf("Anzahl Meldungen"))+linenr});
        }
        if (ala.inputfields != undefined) {
          ala.inputfields.forEach(infi => {
             XLSX.utils.sheet_add_aoa(dataWS, [[infi.answer]], {origin: excelGetColName(collums.indexOf(infi.name))+linenr});
          })

        }
      })

//      console.log(collums)
//      console.log(collums.indexOf("Alarmname"))
//      XLSX.utils.sheet_add_aoa(dataWS, [['NEW VALUE from NODE']], {origin: 'E7'});



      // finischen
      var wb = XLSX.utils.book_new() // make Workbook of Excel
      XLSX.utils.book_append_sheet(wb, dataWS, 'export') // sheetAName is name of Worksheet
      XLSX.writeFile(wb, this.gettargetName(this.currentListID)+'.xlsx') // name of the file is 'book.xlsx'      


    },
    downloadexcelold() {
      // https://lovemewithoutall.github.io/it/json-to-excel/
      // export json to Worksheet of Excel
      // only array possible

/*      pokemons: [
                  {"name": "pikachu", "category": "pokemon"}
                  ,{"name": "Arbok", "category": "pokemon"}
                  ,{"name": "Eevee", "category": "pokemon"}
                ]    */


      var jsondata = []
      this.currentList.forEach(ala => {
        var line = {"_Alarmname": ala.alerttitle, "_Alarmhinweis": ala.alerttargettext, "_Alarmdatum": dateToStringDDMMYYYYTIME(ala.date)}
        if (ala.inputfields) {
          ala.inputfields.forEach(infi => {
            line[infi.name] = infi.answer
          })

        }

        jsondata.push(line)
      });


      var dataWS = XLSX.utils.json_to_sheet(jsondata) 
//      var pokemonWS = XLSX.utils.json_to_sheet(this.pokemons) 

      // https://gitbrent.github.io/xlsx-js-style/
      dataWS["A1"].s = {
        font: {
          name: "Calibri",
          sz: 24,
          bold: true,
          color: { rgb: "FFFFAA00" },
        },
      };

//      dataWS["E7"].v = "fsdfsdf"
      XLSX.utils.sheet_add_aoa(dataWS, [['NEW VALUE from NODE']], {origin: 'E7'});
      dataWS["E7"].s = {
        font: {
          bold: true,
        },
      };


      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new() // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, dataWS, 'animals') // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, this.gettargetName(this.currentListID)+'.xlsx') // name of the file is 'book.xlsx'      


    },


  }
}

</script>
