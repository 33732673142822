
<template>
  <div>
  <v-snackbar v-model="snackbar" :timeout="5000" top :color=snackbarcolor>{{snackbartext}} <v-btn icon @click="snackbar=false"><v-icon>mdi-close</v-icon></v-btn></v-snackbar>
    <v-toolbar flat color="white">
      <v-toolbar-title>{{gettext("dialoghead")}}<v-icon>mdi-account-arrow-left</v-icon></v-toolbar-title>
    </v-toolbar>
    <v-card elevation="2" outlined>
      <v-card-title>Allgemeines</v-card-title>
      <v-card-text>
        <v-text-field label="Name" prepend-icon="mdi-account" type="text" @change="save()" v-model="current_target.targettitle"></v-text-field>


      </v-card-text>
    </v-card>
    <br>
    <v-card elevation="2" outlined>
      <v-card-title> Zieladresse</v-card-title>
      <v-card-text>
        <div v-show="current_target.type=='new'">
          <v-btn  color="primary" dark class="ma-2" @click="current_target.type='mail';save()"><v-icon>{{ getIcon("mail") }}</v-icon> {{getTypeLabelFromType('mail')}}</v-btn>
          <v-btn  color="primary" dark class="ma-2" @click="current_target.type='telegram';save()"><v-icon>{{ getIcon("telegram") }}</v-icon> {{getTypeLabelFromType('telegram')}}</v-btn>
          <v-btn  color="primary" dark class="ma-2" @click="current_target.type='crowdalertslist';save()"><v-icon>{{ getIcon("crowdalertslist") }}</v-icon> {{getTypeLabelFromType('crowdalertslist')}}</v-btn>
        </div>
        <div v-show="current_target.type=='mail'">
          <v-text-field :label="getTargetAdressLabel(current_target.type)" :prepend-icon="getIcon(current_target.type)" type="text" @change="save()" 
            v-model="current_target.adress"></v-text-field>
          <div v-show="isVerified()"> 
            <v-icon color="green">mdi-check-bold</v-icon>
              Benachrichtigungsziel bestätigt
          </div>
          <div v-show="!isVerified()" class="red--text"> 
            <v-icon color="red">mdi-alert</v-icon> ZIEL NICHT BESTÄTIGT
            <v-btn  color="primary" dark class="ma-2" @click="sendVerification()">Bestätigungslink senden</v-btn>
          </div>
        </div>
        <div v-show="current_target.type=='telegram'">
          <v-icon >{{ getIcon(current_target.type) }}  </v-icon> {{getTypeLabelFromType(current_target.type)}}

          <div v-show="isVerified()"> 
            <v-icon color="green">mdi-check-bold</v-icon>
              Benachrichtigungsziel eingerichtet
          </div>
          <div v-show="!isVerified()" class="red--text"> 
            <div v-show="current_target.hash=='KICKED'"> Chat wurde auf dem Telefon gelöscht, bitte wieder einrichten!</div>
            <div color="black"> Zur Einrichtung muss dieser Link mit dem Telegram Client geöffnet und anschließend auf "Start" gedrückt werden</div>
            <div color="black"> {{getTelegramStartLink()}} <br>
            <v-btn color="primary" dark class="ma-2" @click="copyLinkclip()">Link in Zwischenablage kopieren</v-btn>  
            </div>
          </div>
        </div>
        <div v-show="current_target.type=='crowdalertslist'">
          <v-icon >{{ getIcon(current_target.type) }}  </v-icon> {{getTypeLabelFromType(current_target.type)}}
          <div> 
            <v-icon color="green">mdi-check-bold</v-icon>
              Liste wurde angelegt
          </div>
            
        </div>
        
      </v-card-text>
    </v-card>
    <br>
        <v-toolbar flat color="white">
          <v-spacer></v-spacer>
          <v-btn color="grey" dark class="ma-2" @click="back()">Zurück zur Übersicht</v-btn>
        </v-toolbar>



  </div>
</template>

<script>
import { db } from "../firebase";
import { accountUUID } from "../user.js";
import router from '../router';
import { interal_site_login_check } from "../user.js";
import { getIconFromType } from "../helpers.js";
import { getTypeLabelFromType } from "../helpers.js";
import { getTargetAdressLabelFromType } from "../helpers.js";
import { funhash } from "../helpers.js";
import { isVerified } from "../helpers.js";

export default {
  name: "targetedit",
  data() {
    return {
      ownurl : "https://crowdalerts.net/a/", 
      current_target: {},
      snackbar: false,
      snackbartext: "",
      snackbarcolor: "",
    };
  },
  
  firestore() {
    return {
      current_target: db.collection('userbase').doc(accountUUID()).collection('targets').doc(this.getid())        
    };
  },
  components: {
  },
  created(){
     interal_site_login_check()
  },

  methods: {
    gettext(position) {
      if (position == "dialoghead") {
        if (this.isNew()) {
          return "Neues Benachrichtigungsziel anlegen"
        }
        if (this.isNewList()) {
          return "Neues Benachrichtigungsziel als Crowdalerts Liste anlegen"
        }
        else {
          return "Benachrichtigungsziel bearbeiten"

        }
      }
//      console.log("DialogOpenedCallback");
    },
    back() {
  //    console.log("back");
      router.back()
    },
    save() {
//      console.log("save");
      if (this.current_target.type=="crowdalertslist") {
        this.current_target.hash = funhash(this.current_target.type+this.current_target.adress)
      }
      db.collection('userbase').doc(accountUUID()).collection('targets').doc(this.current_target.id).set(this.current_target)
    },

    deletetarget()  {
      db.collection('userbase').doc(accountUUID()).collection('targets').doc(this.current_target.id).delete()
      router.push('targets')
    },
    getid() {
      let params = this.$route.params
      return params.targetId
    },
    isNew() {
      let params = this.$route.params
      if (params.aim == "new") {
        return true
      }
      return false
    },
    isNewList() {
      let params = this.$route.params
      if (params.aim == "newlist") {
        return true
      }
      return false
    },
    getIcon(type) {
      return getIconFromType(type)
    },
    getTargetAdressLabel(type) {
      return getTargetAdressLabelFromType(type)
    },
    getTypeLabelFromType(type) {
      return getTypeLabelFromType(type)
    },
    
    sendVerification() {
      if (this.current_target.type === "mail") {
        let hash = funhash(this.current_target.type+this.current_target.adress)
        let adress = this.current_target.adress
        let subject = "CrowdAlert Bestätigunglink "
        let content = "Link: http://crowdalerts.net/verify/"+accountUUID()+"/"+this.current_target.id+"/"+hash
        db.collection('ztmp_emailsend').add({email: adress, subject: subject, html: content, date: Date.now()})
        db.collection('userbase').doc(accountUUID()).collection('targets').doc(this.current_target.id).set(this.current_target)

        this.snackbartext = "Bestätigunglink gesendet"
        this.snackbarcolor = "success"
        this.snackbar = true
      }
    },
    getTelegramStartLink(){
      return "http://t.me/crowdalerts_bot?start="+accountUUID()+"_"+this.current_target.id

    },
    isVerified() {
      return isVerified(this.current_target.type,this.current_target.adress, this.current_target.hash)
    },
    copyLinkclip(){
//      console.log("copyLinkclip")
      navigator.clipboard.writeText(this.getTelegramStartLink()).then(function() {
            /* success */      
          self.snackbartext = "Link wurde in Zwischenablage kopiert"
          self.snackbarcolor = "success"
          self.snackbar = true
        }, function() {
            /* failure */
          self.snackbartext = "Kopieren fehlgeschlagen"
          self.snackbarcolor = "error"
          self.snackbar = true
        });
    }
  }
};



</script>

