import jsPDF from 'jspdf'
import { dateToStringDDMMYYYY, getTerm } from "./helpers.js";

export default class Bill {

  constructor(billdata) {
    this.adress1 = billdata.account.adress1;
    this.adress2 = billdata.account.adress2;
    this.adress3 = billdata.account.adress3;
    this.adress4 = billdata.account.adress4;
    this.knr = billdata.account.knr;
    this.date = dateToStringDDMMYYYY(billdata.date)
    this.untildate = dateToStringDDMMYYYY(billdata.date+1000 * 60 * 60 * 24 * billdata.billpayperiod)
    this.billnr = billdata.billnumber
    this.item1text = billdata.item1text
    this.item1price = billdata.item1price
    this.mwst = billdata.mwst

  }

  float2String(price) {
    var euro = Math.floor(price)
    var cent = Math.round((price - euro) * 100) + ""
    if (cent.length < 2) {
      cent = "0" + cent
    }
    return euro + "," + cent;
  }

  createpdf() {
    // https://mrrio.github.io/
    var doc = new jsPDF();
    var xlinks = 20
    var xrechts = 190
    var ylogo = 20
    var ymail = 30
    var yadress = 53
    var yadressspace = 6
    var ydate = 84
    var ytitle = 97
    var yitems = 110
    var ymwst = 170
    var ytotal = 190
    var ytextbelow = 210

    doc.setFont("helvetica", "bold").setFontSize(26);
    doc.text(xrechts, ylogo, 'crowdalerts.net', 'right');

    doc.setFont("helvetica", "normal").setFontSize(8);
    doc.text(xrechts, ymail, 'mail@crowdalerts.net', 'right');
    doc.text(xrechts, ymail += 4, '0178 1986532', 'right');
    doc.text(xrechts, ymail += 4, 'Umsatzsteuernummer DE293135362', 'right');
    doc.setFont("helvetica", "bold").setFontSize(8);
    doc.text(xrechts, ymail += 8, 'Ihre Kundennummer: '+ this.knr, 'right');

    doc.setFont("helvetica", "normal").setFontSize(8);
    doc.text(xlinks, yadress, 'crowdalerts.net | Mozartstr. 14 | 73269 Hochdorf');
    doc.setFont("helvetica", "normal").setFontSize(12);
    doc.text(xlinks, yadress += yadressspace, this.adress1);
    doc.text(xlinks, yadress += yadressspace, this.adress2);
    doc.text(xlinks, yadress += yadressspace, this.adress3);
    doc.text(xlinks, yadress += yadressspace, this.adress4);

    doc.text(xrechts, ydate, this.date, 'right');

    doc.setFont("helvetica", "bold").setFontSize(12);
    doc.text(xlinks, ytitle, 'Rechnung Nr. ' + this.billnr);

    doc.setFont("helvetica", "normal").setFontSize(12);
    doc.text(xlinks, yitems, this.item1text);
    doc.text(xrechts, yitems, this.float2String(this.item1price) + " Euro", 'right');

    doc.text(xlinks, ymwst, "MwSt. ("+ this.mwst + "%)");
    doc.text(xrechts, ymwst, this.float2String(this.item1price * (this.mwst/100)) + " Euro", 'right');

    doc.setFont("helvetica", "bold").setFontSize(12);
    doc.text(xlinks, ytotal, "Gesamtsumme");
    doc.text(xrechts, ytotal, this.float2String(this.item1price * (1+this.mwst/100)) + " Euro", 'right');

    doc.setFont("helvetica", "normal").setFontSize(12);
    doc.text(xlinks, ytextbelow, "Bitte überweisen sie den Rechnungbetrag unter Angabe der Rechnungsnummer\nbis zum " + this.untildate + " auf das unten angegebene Konto.");
    doc.text(xlinks, ytextbelow + 15, "Solarisbank AG   IBAN:  DE68 1101 0101 5540 2736 76   BIC: SOBKDEB2XXX ");
    doc.text(xlinks, ytextbelow + 30, "Wir bedanken uns für Ihren Auftrag,\nIhr crowdalerts.net Team");

    return doc
  }

}

