<template>
  <div>
  <v-snackbar v-model="snackbar" :timeout="5000" top :color=snackbarcolor>{{snackbartext}} <v-btn icon @click="snackbar=false"><v-icon>mdi-close</v-icon></v-btn></v-snackbar>
    <v-toolbar flat color="white">
      <v-toolbar-title>{{gettext("dialoghead")}}<v-icon>mdi-alarm-light</v-icon></v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form>
    <v-card elevation="2" outlined>
      <v-card-title> Allgemeines</v-card-title>
      <v-card-text>
        <v-text-field id="alertedit-name" label="Name" prepend-icon="mdi-alarm-light" type="text" @change="save()" v-model="current_alert.alerttitle"></v-text-field>
        <v-text-field id="alertedit-hinweis" label="Hinweise, die an das Ziel gesendet werden" prepend-icon="mdi-account-arrow-left" type="text" @change="save()" v-model="current_alert.alerttargettext"></v-text-field>
      </v-card-text>
    </v-card>
    <br>

    <v-card elevation="2" outlined>
      <v-card-title> Alarmkonfiguration</v-card-title>
        <v-card-text>
          <v-switch id="alertedit-immediate" v-model="current_alert.immediate" label="Alarm sofort, ohne zusätzlichen Senden Button aktivieren"  @click="save()"></v-switch>

          <v-text-field id="alertedit-text" v-show="!current_alert.immediate" label="Text, den der Benutzer vor dem Senden angezeigt bekommt" prepend-icon="mdi-tablet-android" type="text" @change="save()" v-model="current_alert.alertinputtext"></v-text-field>

          <v-card-title  v-show="!current_alert.immediate && current_alert.inputfields.length>0"> Eingabefelder</v-card-title>

            <v-card-text v-show="!current_alert.immediate && current_alert.inputfields.length>0">
              <v-data-table :headers="inputheaders" :items="current_alert.inputfields" dense class="elevation-3">
                <template v-slot:[`item.name`]="{item}">
                  <v-text-field  type="text" @change="save()" v-model="item.name" ></v-text-field>
                </template>
                <template v-slot:[`item.text`]="{item}">
                  <v-text-field  type="text" @change="save()" v-model="item.text" ></v-text-field>
                </template>
                <template v-slot:[`item.type`]="{item}">
                  <v-select v-model="item.type" :items="inputtypes" item-text="name" @change="save()"></v-select>
                </template>
                <template v-slot:[`item.mandatory`]="{item}">
                  <v-simple-checkbox :ripple="false" v-model="item.mandatory" @click="save()"></v-simple-checkbox>
                </template>
                <template v-slot:[`item.del`]="{item}">
                  <v-btn @click="deleteInputfield(current_alert.inputfields.indexOf(item))" target="_blank" text ><v-icon>mdi-trash-can</v-icon></v-btn>
                </template>
              </v-data-table>
            </v-card-text>


        <v-btn id="alertedit-fields" v-show="!current_alert.immediate" color="primary" dark class="ma-2" @click="addInputfield()">Eingabefeld hinzufügen</v-btn>
      </v-card-text>
    </v-card>
  <br>

    <v-card elevation="2" outlined>
      <v-card-title> Texte für den Alarmierenden</v-card-title>
      <v-card-text>
        <v-text-field id="alertedit-thanks" label="Text, den der Benutzer nach dem Senden angezeigt bekommt" prepend-icon="mdi-tablet-android" type="text" @change="save()" v-model="current_alert.alertusertext"></v-text-field>
      </v-card-text>
    </v-card>
    <br>
    <v-card elevation="2" outlined>
      <v-card-title id="alertedit-targets"> Benachrichtigungsziele</v-card-title>
      <v-card-text>
        <v-data-table :headers="headers" :items="users_targets_localcopy" dense class="elevation-3">
          <template v-slot:[`item.name`]="{item}">
            {{ item.targettitle }}
          </template>
          <template v-slot:[`item.adress`]="{item}">
            <v-icon >{{getIcon(item.type)}}</v-icon>
            {{ item.adress }}
          </template>
          <template v-slot:[`item.warn`]="{item}">
              <span v-show="!isVerified(item.type,item.adress,item.hash)&&item.active" class="red--text">
                <v-icon color="red">mdi-alert</v-icon> ZIEL NICHT BESTÄTIGT
              </span> 
              <span v-show="!isVerified(item.type,item.adress,item.hash)&&!item.active">
                <v-icon> mdi-alert</v-icon> ZIEL NICHT BESTÄTIGT
              </span> 
          </template>
          <template v-slot:[`item.active`]="{item}">
            <v-simple-checkbox id="alertedit-preset" :ripple="false" v-model="item.active"  :label="gettextActive(item.active)" @click="save()"></v-simple-checkbox>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <br>

    <v-card elevation="2" outlined>
      <v-card-title> Code und Hinweisschild</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <canvas id="qrcanvas" width="300" height="300"></canvas>
            <p>{{QRlink()}} </p>
            <v-btn color="primary" dark class="ma-2" @click="downloadQR()">Download QR-Code</v-btn>
          </v-col>
          <v-col>
            <v-text-field label="Titel für Hinweisschild" prepend-icon="mdi-text-box-outline" type="text" @change="save()" v-model="current_alert.signtitle"></v-text-field>
            <v-textarea label="Text für Hinweisschild" prepend-icon="mdi-text-box-outline" type="text" @change="save()" v-model="current_alert.signtext" auto-grow></v-textarea>
            <v-btn id="alertedit-sign" color="primary" dark class="ma-2" @click="downloadSign()">Hinweisschild generieren</v-btn>
          </v-col>
        </v-row> 
      <div> 
      </div>
      </v-card-text>
    </v-card>


    <br>
      <v-toolbar flat color="white">
        <v-spacer></v-spacer>
        <v-btn color="primary" dark class="ma-2" @click="testalert()">Alarm testen</v-btn>
        <v-btn color="grey" dark class="ma-2" @click="back()">Zurück zur Übersicht</v-btn>
      </v-toolbar>
    </v-form>

  </div>
</template>

<script>
import { db } from "../firebase";
import { accountUUID } from "../user.js";
import router from '../router';
import { interal_site_login_check } from "../user.js";
import { getIconFromType } from "../helpers.js";
import { isVerified } from "../helpers.js";
import { getConstant } from "../constants.js";
import Sign from "../pdfsign.js";


export default {
  name: "alertedit",
  data() {
    return {
      userdb: {},
      current_alert: {inputfields: []},
      users_targets: [],
      users_targets_localcopy: [],
      headers: [
        {text: 'Name', value: 'name', align: 'left'},
        {text: 'Adresse', value: 'adress', align: 'left'},
        {text: '', value: 'warn', align: 'left'},
        {text: 'Aktiviert', value: 'active', align: 'left'},
      ],
       
      inputheaders: [
        {text: 'Interner Name', value: 'name', align: 'left'},
        {text: 'Text für Benutzer', value: 'text', align: 'left'},
        {text: 'Typ', value: 'type', align: 'left'},
        {text: 'Pfichtfeld', value: 'mandatory', align: 'left'},
        {text: '', value: 'del', align: 'left'},
      ],
      inputtypes: [
        // value = html input type
        // https://www.w3schools.com/html/html_form_input_types.asp
        {value: 'text', name: 'Text'},
        {value: 'number', name: 'Zahl'},
      ],
      snackbar: false,
      snackbartext: "",
      snackbarcolor: "",
    };
  },
  firestore() {
    return {
      current_alert: db.collection('userbase').doc(accountUUID()).collection('alerts').doc(this.getid()),        
      users_targets: db.collection('userbase').doc(accountUUID()).collection('targets').where('deleted', '==', false),
      userdb: db.collection('userbase').doc(accountUUID()),
    };
  },
  components: {
  },
  // TODO weil man immer speichert, kann man das hier einfacher machen. so wie es ist wird auch nicht upgedtate wenn im hintergrund eine adresse bestätigt wird
  created(){
    interal_site_login_check()
    let tarref = db.collection('userbase').doc(accountUUID()).collection('targets').where('deleted', '==', false)
    tarref.get().then(snapshot => {
      snapshot.forEach(doc => {
        let xxx = doc.data()
        xxx.id = doc.id 
        xxx.active = false
        if (this.current_alert.targets !== undefined && this.current_alert.targets.includes(doc.id)) {
          xxx.active = true
        }
      this.users_targets_localcopy.push(xxx)
      });
      console.log(this.users_targets_active)
    })
    .catch(err => {
      console.log('Error getting documents', err);
    });
    
  },
  updated(){
    this.generateQR()
  },

  methods: {
    gettext(position) {
      if (position == "dialoghead") {
        if (this.isNew()) {
          return "Alarm anlegen"
        }
        else {
          return "Alarm bearbeiten"

        }
      }
    },
    gettextActive(booool) {
      if (booool) {
        return "Aktivert"
      }
      else {
        return "Deaktivert"

      }
    },
    getIcon(type) {
      return getIconFromType(type)
    },
    findWithAttr(array, attr, value) {
      for(var i = 0; i < array.length; i += 1) {
          if(array[i][attr] === value) {
              return i;
          }
      }
      return -1;
    },
    save() {
      this.current_alert.targets = []
      this.users_targets_localcopy.forEach(item => {
        if (item.active) {
          this.current_alert.targets.push(item.id)
        }
      });

      if (this.current_alert.targets.length==0) {
        this.snackbartext = "Es sind keine Ziele aktiviert, der Alarm wird nirgends angezeigt!"
        this.snackbarcolor = "error"
        this.snackbar = true

      }

      let tc = this.current_alert.triggercode
      db.collection('userbase').doc(accountUUID()).collection('alerts').doc(this.current_alert.id).set(this.current_alert)
      db.collection('publicalerts').doc(tc).set(this.current_alert)
    },
    back() {
      router.push('alerts')
    },
    testalert() {
      
      
      var allfine = true
      this.users_targets_localcopy.forEach(item => {
        if (item.active && !isVerified(item.type, item.adress, item.hash)) {
          allfine = false
        }
      });
      if (!allfine) {
      this.snackbartext = "Es sind nicht alle Ziele bestätigt"
        this.snackbarcolor = "error"
        this.snackbar = true
      }
      if (this.current_alert.targets.length==0) {
        this.snackbartext = "Es sind keine Ziele aktiviert, der Alarm wird nirgends angezeigt!"
        this.snackbarcolor = "error"
        this.snackbar = true
        allfine = false
      }

      if (allfine) {
        window.open(this.QRlink(), '_blank');      
      }
    },
    deletealert()  {
      db.collection('userbase').doc(accountUUID()).collection('alerts').doc(this.current_alert.id).delete()
      router.push('alerts')
    },
    getConstant(x) {
      return getConstant(x)
    },
    getid() {
      let params = this.$route.params
      return params.alertId
    },
    isNew() {
      let params = this.$route.params
      if (params.aim == "new") {
        return true
      }
      return false
    },
    isVerified(type, adress, hash) {
      return isVerified(type, adress, hash)
    },
    generateQR() {
      var QRCode = require('qrcode')
      var canvas = document.getElementById('qrcanvas')
       
       // https://www.npmjs.com/package/qrcode
//      QRCode.toCanvas(canvas, this.getConstant('ownurl')+this.typelink()+this.current_alert.triggercode, function (error) {
      QRCode.toCanvas(canvas, this.QRlink(),{ width: 200 }, function (error) {
        if (error) console.error(error)
      })

    },
    QRlink() {
      return  this.getConstant('ownurl')+"a/"+this.current_alert.triggercode
    },
    Routerlink() {
      return  "a/"+this.current_alert.triggercode
    },
    /*
    copyQRclip() {
      var canvas = document.getElementById('qrcanvas')
      var self = this;
      canvas.toBlob(function (blob) {
        let data = [new ClipboardItem({ [blob.type]: blob })];
        navigator.clipboard.write(data).then(function() {
            
          self.snackbartext = "Code wurde in Zwischenablage kopiert"
          self.snackbarcolor = "success"
          self.snackbar = true
        }, function() {
            
          self.snackbartext = "Kopieren fehlgeschlagen"
          self.snackbarcolor = "error"
          self.snackbar = true
        });
      });
    },
    */
    downloadQR() {
      var canvas = document.getElementById('qrcanvas')
      var gh=(canvas.toDataURL('png'));
      var a  = document.createElement('a');
      a.href = gh;
      a.download = this.current_alert.alerttitle.replace(/[^a-zA-Z0-9]/g,'_') + ".png";
      if (this.current_alert.alerttitle.length<2) {
        a.download = "code.png";
      }
      a.click()
    },
    downloadSign(){
      this.sign = new Sign()
      this.sign.setData(this.current_alert.signtitle, this.current_alert.signtext, this.QRlink(), this.current_alert.alerttitle)
      var doc = this.sign.createpdf();
      var filename = this.current_alert.alerttitle.replace(/[^a-zA-Z0-9]/g,'_') + ".pdf";
      if (this.current_alert.alerttitle.length<2) {
        var filename = "sign.pdf";
      }
      doc.save(filename)
    },
    addInputfield() {
      if (this.current_alert.inputfields == null) {
        this.current_alert.inputfields = []
      }

      this.current_alert.inputfields.push({name: "Feld "+(this.current_alert.inputfields.length+1), text:"", type:"TEXT"})
      this.save()
//      db.collection('userbase').doc(accountUUID()).collection('alerts').doc(this.current_alert.id).collection('inputfields').add({name: "gddf", text:"defalut text", type:"TEXT"})
    },
    deleteInputfield(id) {

      this.current_alert.inputfields.splice(id,1)
      this.save()
    }



  }
};

</script>
