<template>
  <div class="home">
<h1>Willkommen bei <b>crowdalerts.net</b></h1>

<h2>Was ist <b>crowdalerts.net</b>?</h2>
<p><b>crowdalerts.net</b> ist ein einfach zu bedienender Benachrichtigungsservice! Sie richten einen Alarm ein, für den automatisch ein QR Code generiert wird 
Wenn nun eine Person vor Ort etwas melden möchte, scannt sie einfach dieses Code und löst somit den Alarm aus. Der Alarm wird dann automatisch an die konfigurierten Zieladressen weitergeleitet.

<div class="d-flex flex-column justify-space-between align-center">
<v-img src="schema.PNG" max-width="300" ></v-img>
</div>

<p>Beispiele für die Verwendung von <b>crowdalerts.net</b> gibt es viele. Hier finden Sie eine kleine Auswahl:</p>


 <v-tabs vertical>
  <v-tab v-for="item in examplepics" :key="item"> 
    {{item.text}} 
  </v-tab>
  <v-tab-item v-for="item in examplepics" :key="item"> 
    <div class="d-flex flex-column justify-space-between align-center">
       <v-img :src="item.src" ></v-img>
    </div>
  </v-tab-item>
 </v-tabs>




<h2>Vorteile</h2>
Die Vorteile dieser Digitalisierungslösung sind:
<ul>
<li>Besserer Service durch mehr Informationen der Lage vor Ort.</li>
<li>Kostenersparnis durch schnellere und kürzere Kommunikation.</li>
<li>Kosten- und Energieersparnis durch Wegfall unnötiger Wege.</li>
</ul>


<br>
<h2>Individuelle Alarme</h2>
<p>Je nach Anwendungsfall können Alarme können verschieden konfiguriert werden:</p>
<ul>
<li>Nur Scan: der Alarmierende scannt den Code und der Alarm wird sofort ausgelöst. Ideal für alle Alarme, bei denen die Information klar ist, wie z.b. „Papier leer“, „Mülleimer voll“, oder auch schlicht „Hier muss etwas getan werden“</li>
<li>Mit Bestätigung: der Alarmierende scannt den Code und der Alarm und es erscheint zunächst die Frage, ob der Alarm ausgelöst werden soll. Gut für Alarme, bei denen Inetressierte einfach so mal den Code scannen. </li>
<li>Ein oder mehrere Eingabefelder: der Alarmierende scannt den Code und kann in Eingabefeldern weitere Informationen hinterlassen. Ideal für Alarme, bei denen noch mehr Informationen gesendet werden müssen, etwa für Schadensmeldungen oder Verbesserungsvorschläge.</li>
</ul>

<h3> Fangen Sie gleich an, mit der langer Testphase von 60 Tagen können Sie alles ausprobrieren!</h3>
<br><br>

<!--
<h2>Ihre Vorteile</h2>
<li>Wenn der Alarm einmal angelegt ist, bleibt der QR Code immer gleich. Im Hintergrund können Sie aber jederzeit die Ziele ändern.</li>
<li>Zusätzlich zu den Mitteilungen per email oder Messengerdienst kann die Nachricht auch auf To-Do Listen eingetragen werden</li>
-->


  </div>
</template>

<script>
// @ is an alias to /src
//import LoggedIn from '@/components/LoggedIn.vue'
//import LoggedOut from '@/components/LoggedOut.vue'
//import firebase from 'firebase'
import {isLoggedIn} from '../user.js'

export default {
  name: 'home',
  data() {
    return {
      examplepics: [
        {text:"Schadensmeldung auf Spielplatz", src: 'spielplatz.PNG',},
        {text:"Entsorgung auf Bestellung", src: 'muell.PNG',},
      ]  
    }
  },  components: {
//    LoggedIn,
//    LoggedOut
  },
  mounted() {
    let params = this.$route.params
    if (params.starttour == "true") {
      this.$tours['myTourStart'].start()
    }

    
  },
  methods: {
    checklogin: function() {
      return isLoggedIn()
    }
  }

}

</script>


<!-- <li>	Ein Spielplatz, an dem etwas defekt ist. Hier könnte man den Schaden telefonisch bei der Stadtverwaltung melden… aber eben nicht am Sonntagnachmittag</li>
<li>Der Wassersprudler in der Kaffeecke hat mal wieder kein Gas oder im Besprechungsraum ist ein Licht kaputt, hier sollte jemand den Hausservice anrufen, der ist aber mittendrin bei einer anderen Aufgabe</li>
<li>Ein abgelegener Mülleimer wird immer im selben Turnus geleert. Manchmal ist er fast leer, manchmal quillt er schon über. Wie viel Zeit und Ärger würde es sparen, wenn jemand vor Ort mitteilen würde, wenn er voll ist?</li>
<li>Und schon wieder hat jemand das letzten Paket Papier in den Drucker gelegt und nicht Bescheid gesagt….</li>
</ul>
<p></p>
<p>Sehr oft wird einfach gar nichts gemeldet, denn wer merkt sich den die Nummer der Stadtverwaltung bis Montag, oder welche Mitarbeiter ruft zurück am Arbeitsplatz dann wirklich den Hausservice an?<p>
<b>crowdalerts.net</b> ist hier die Lösung! 




<v-carousel hide-delimiters>
  <v-carousel-item
      v-for="(item,i) in examplepics"
      :key="i"
      reverse-transition="fade-transition"
      transition="fade-transition"
    >
    <div class="d-flex flex-column justify-space-between align-center">
       <v-img :src="item.src" ></v-img>
    </div>
    </v-carousel-item>
</v-carousel>

-->