<template>
  <v-container>
  <v-snackbar v-model="snackbar" :timeout="5000" top :color=snackbarcolor>{{snackbartext}} <v-btn icon @click="snackbar=false"><v-icon>mdi-close</v-icon></v-btn></v-snackbar>
  <v-row align="start" justify="center">
    <v-col cols="auto">
    <v-card class="elevation-12">
      <v-toolbar color="primary" dark flat >
        <v-toolbar-title>Passwort zurücksetzen</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-form>
            <v-text-field
            label="Login"
            name="login"
            prepend-icon="mdi-account"
            type="text"
            v-model="email"
            ></v-text-field>

        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="reset">Reset</v-btn>
      </v-card-actions>
    </v-card>
    </v-col>
  </v-row>
  </v-container>
</template>

<script>

import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
// import router from '../router'

export default {
  name: "login",
  data: () => ({
    snackbar: false,
    snackbartext: "",
    snackbarcolor: "",
    email: "",

  }),
  methods: {
    reset: function() {
        var self = this;
      firebase.auth().sendPasswordResetEmail (this.email).then(
          function() {
            self.snackbartext = "Email wurde gesendet"
            self.snackbarcolor = "success"
            self.snackbar = true

          },
          function(err) {
              // https://firebase.google.com/docs/auth/admin/errors
            // alert("Oops: " + err.message+ "  "+ err.code);
            
            if (err.code == "auth/invalid-email") {
                self.snackbartext = "keine Emailadresse"
            }
            else if (err.code == "auth/user-not-found") {
                self.snackbartext = "Es existiert kein Account mit dieser Emailadresse"
            }
            else {
                self.snackbartext = "ERROR: " + err.message+ "\ncode: "+ err.code
                console.log(err.code)
            }
            self.snackbarcolor = "error"
            self.snackbar = true

          }
        );
    }
  }
};
</script>

