export function getTerm(term) {
  // Material Icons https://iconify.design/icon-sets/mdi/ 
  if (term == "MONTH") {
    return "1 Monat"
  }
  if (term == "YEAR") {
    return "1 Jahr"
  }
  if (term == "NOTCHOOSEN") {
    return "Nicht gewählt"
  }

  return ""
}
export function getIconFromType(type) {
  // Material Icons https://iconify.design/icon-sets/mdi/ 
  if (type == "mail") {
    return "mdi-at"
  }
  if (type == "twitter") {
    return "mdi-twitter"
  }
  if (type == "whatsapp") {
    return "mdi-whatsapp"
  }
  if (type == "skype") {
    return "mdi-skype"
  }
  if (type == "crowdalertslist") {
    return "mdi-playlist-check"
  }
  if (type == "signal") {
    return "mdi-chat"
  }
  if (type == "telegram") {
    return "mdi-send"  // TODO eigentlich falsch
  }
  return "mdi-pirate"
}

export function getTargetAdressLabelFromType(type) {
  if (type == "mail") {
    return "Email Adresse"
  }
  if (type == "twitter") {
    return "Twitter Adresse"
  }
  if (type == "whatsapp") {
    return "Telefonnummer"
  }
  if (type == "sms") {
    return "Telefonnummer"
  }
  if (type == "skype") {
    return "Skype Adresse"
  }
  if (type == "crowdalertslist") {
    return "crowdalerts.net Liste Listenname"
  }
  if (type == "signal") {
    return "Signal Messenger Nummer (Telefonnummer)"
  }
  if (type == "telegram") {
    return "Telegram Kontakt"
  }
  return ""
}

export function getTypeLabelFromType(type) {
  // TARGETS
  if (type == "mail") {
    return "Email"
  }
  if (type == "twitter") {
    return "Twitter"
  }
  if (type == "sms") {
    return "SMS"
  }
  if (type == "whatsapp") {
    return "Whatsapp"
  }
  if (type == "skype") {
    return "Skype"
  }
  if (type == "crowdalertslist") {
    return "Crowdalert Liste"
  }
  if (type == "signal") {
    return "Signal Messenger"
  }
  if (type == "telegram") {
    return "Telegram Messenger"
  }
  // ALERTS
  if (type == "scan") {
    return "Nur Scan"
  }
  if (type == "input") {
    return "Ein Eingabefeld"
  }
  return ""
}

export function funhash(s) {
  for (var i = 0, h = 0xdeadbeef; i < s.length; i++) {
    h = Math.imul(h ^ s.charCodeAt(i), 2654435761);
  }
  return (h ^ h >>> 16) >>> 0;
};

export function isVerified(type, adress, hash) {
  if (type == "telegram" && hash=="TELEGRAMOK"){
    return true;
  }
  if (funhash(type + adress) == hash) {
    return true;
  }
  return false;
};

export function dateToStringDDMMYYYY(datelong) {
  if (datelong> 4000000000000) {
    return "unbegrenzt"
  }
  var d = new Date(datelong)
  return d.getDate()+"."+(d.getMonth()+1)+"."+d.getFullYear()
};

export function dateToStringDDMM(datelong) {
  var d = new Date(datelong)
  return d.getDate()+"."+(d.getMonth()+1)+"."
};

export function dateToStringMMYYYY(datelong) {
  var d = new Date(datelong)
  return (d.getMonth()+1)+"/"+d.getFullYear()
};


export function dateToStringDDMMYYYYTIME(datelong) {
  if (datelong> 4000000000000) {
    return "unbegrenzt"
  }
  var d = new Date(datelong)
  //console.log (re)
  var re = dateToStringDDMMYYYY(datelong) + " " + dateToStringTIME(datelong)
  return re
};

export function dateToStringExcel(datelong) {
  var d = new Date(datelong)
  //console.log (re)
  var re = dateToStringDDMMYYYY(datelong) + " " + dateToStringTIME(datelong)
  return re
};

export function dateToStringTIME(datelong) {
  var d = new Date(datelong)
  //console.log (re)
//  return datelong.toLocaleDateString('de')
  if (d.getMinutes()<10) {
    return d.getHours()+":0"+d.getMinutes()
  }
  return d.getHours()+":"+d.getMinutes()
};


export function dateToStringFLEX(datelong, seit) {
  // seit = wenn im satz ein "seit" schon steht
  var now = new Date()
  var d = new Date(datelong)
  var today = new Date()
  today.setMinutes(0)
  today.setHours(0)
  var diff = now-d
  if (datelong> 4000000000000) {
    return "unbegrenzt"
  }
  if (diff<1000*60*60) {// < 1h
    if (seit) {return " " + Math.round(diff/1000/60) + " min"    }
    else { return "vor " + Math.round(diff/1000/60) + " min"}
   
  } 
  if (d>today) {// heute
    if (seit) {return " "+dateToStringTIME(d)}
    else { return "heute, "+dateToStringTIME(d)}
    
  }
  return ""+dateToStringDDMMYYYYTIME(d)
};

export function dateToStringFLEXcompact(datelong) {
  var now = new Date()
  var d = new Date(datelong)
  var today = new Date()
  today.setMinutes(0)
  today.setHours(0)

  var vor6mon = new Date(today-180*(24*60*60*1000))


  if (d>today) {// heute
    return dateToStringTIME(d)    
  }
  if (d>vor6mon) {// heute
    return dateToStringDDMM(d)    
  }

  return dateToStringMMYYYY(d)
};



export function countstring(last) {
  var d = new Date()
  var mon = d.getMonth()+1
  var year = d.getFullYear()
  if (last) {
    mon=mon-1
    if (mon==0) {
      mon=12
      year=year-1
    }
  }
  if (mon<10) {
    return "count"+year+"_0"+mon
  }
  
  return "count"+year+"_"+mon

}



export function excelGetColName(colnum) {
  // TODO > 24
  var r = String.fromCharCode(64+colnum);
  return r
}
