<template>
  <div class="user">
  <v-snackbar v-model="snackbar" :timeout="5000" top :color=snackbarcolor>{{snackbartext}} <v-btn icon @click="snackbar=false"><v-icon>mdi-close</v-icon></v-btn></v-snackbar>
    <v-toolbar flat color="white">
      <v-toolbar-title>Einstellungen<v-icon>mdi-account-edit</v-icon></v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card elevation="2" outlined>
      <v-card-title> Login</v-card-title>
      <v-card-text>
        <!--      <v-text-field label="Name" prepend-icon="mdi-account" type="text" v-model="userdb.account.username" @change="save()"></v-text-field> -->
        <v-text-field label="Login" readonly outlined prepend-icon="mdi-account" type="text" v-model="userdb.account.useruuid"></v-text-field>
        <v-btn color="primary" dark class="mb-2" @click="newPassword()">Passwort ändern</v-btn>
        <v-switch v-model="userdb.account.newsletter" label="Newsletter empfangen"  @change="save()"></v-switch>
      </v-card-text>
    </v-card>
    <br>
    <v-card elevation="2" outlined>
      <v-card-title>Ihr Vertrag </v-card-title>
      <v-card-text>
        <v-text-field label="Kundennummer" readonly outlined prepend-icon="mdi-account" type="text" v-model="userdb.account.knr"></v-text-field>
        Ihr Vertragsart ist {{getAccVal(userdb.account.accounttype, 'name') }} bis zum {{dateToStringDDMMYYYY(userdb.account.paiduntil)}}.<br>

        <div v-if="getAccVal(userdb.account.accountnext, 'level') > 100"> Danach Verlängerung um {{getTerm(userdb.account.period)}} in der Vetragsart {{getAccVal(userdb.account.accountnext, 'name')}} </div>
        <div v-if="userdb.account.accountnext == 'NONE'" class="red--text"><v-icon color="red">mdi-alert</v-icon> Am {{dateToStringDDMMYYYY(userdb.account.paiduntil)}} wird der Account stillgelegt !!</div>
        <div v-if="(userdb.account.accountnext == 'NOTCHOOSEN') && (getAccVal(userdb.account.accounttype, 'level')>99)" class="red--text"><v-icon color="red">mdi-alert</v-icon> Bitte wählen Sie eine Vertagsart!</div>
        
        <v-btn v-if="userdb.account.accountnext == 'NOTCHOOSEN'" color="primary" class="mb-2" @click="$router.push('accounttype')" >Vertrag einrichten</v-btn>
        <v-btn v-else color="primary" class="mb-2" @click="$router.push('accounttype')" >Vertrag ändern</v-btn>

      </v-card-text>
    </v-card>

    <br>
    <v-card elevation="2" outlined>
      <v-card-title> Rechnungsadresse</v-card-title>
      <v-card-text>
        <v-text-field label="Firma" prepend-icon="mdi-account" type="text" v-model="userdb.account.adress1" @change="save()"></v-text-field>
        <v-text-field label="Name" prepend-icon="mdi-account" type="text" v-model="userdb.account.adress2" @change="save()"></v-text-field>
        <v-text-field label="Straße / Hausnummer" prepend-icon="mdi-account" type="text" v-model="userdb.account.adress3" @change="save()"></v-text-field>
        <v-text-field label="PLZ / Ort" prepend-icon="mdi-account" type="text" v-model="userdb.account.adress4" @change="save()"></v-text-field>
        <v-switch v-model="userdb.account.perpost" label="Rechnung per Post"  @change="save()"></v-switch>
      </v-card-text>
    </v-card>
    <br>
    <v-card elevation="2" outlined>
      <v-card-title> Rechnungen</v-card-title>
      <v-card-text>
        <v-data-table :headers="headers" :items="users_bills" class="elevation-3" sort-by="date" sort-desc>
          <template v-slot:[`item.date`]="{item}">
            {{dateToStringDDMMYYYY(item.date)}}
          </template>   
          <template v-slot:[`item.billnumber`]="{item}">
            {{item.billnumber}}
          </template>   
          <template v-slot:[`item.opt`]="{item}">
            <v-btn @click="downloadbill(item)" target="_blank" text ><v-icon>mdi-file-download-outline</v-icon></v-btn>
          </template>   
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>  
</template>

<script>
import { db } from "../firebase";
import { accountUUID } from "../user.js";
import { accountName } from "../user.js";
import { dateToStringDDMMYYYY } from "../helpers.js";
import { interal_site_login_check } from "../user.js";
import { resetPassword } from "../user.js";
import { getAccountValues } from "../constants.js";
import { getTerm } from "../helpers.js";
import { getConstant } from "../constants.js";
import Bill from "../pdfbill.js";

export default {
  name: "user",
  data() {
    return {
      userdb: {account: {}},
      paiduntil: "fdf",
      users_bills: [],
      headers: [
        {text: 'Datum', value: 'date', align: 'left'},
        {text: 'Rechnungsnummer', value: 'billnumber', align: 'left'},
        { text: 'Download', value: 'opt', sortable: false }
      ],
      snackbar: false,
      snackbartext: "",
      snackbarcolor: "",

    }
  },
  firestore() {
    return {
      userdb: db.collection('userbase').doc(accountUUID()),      
      users_bills: db.collection('userbase').doc("null").collection('bills'),
    };
  },
  beforeMount(){
    this.$bind('users_bills',db.collection('userbase').doc(accountUUID()).collection('bills'))
  },
  created() {
    interal_site_login_check()
  },
  updated(){ // das passiert nach firestore
  },
  methods: {
    accountName: function() {
      return accountName();
    },
    save() {
      db.collection('userbase').doc(accountUUID()).update("account", {})
      db.collection('userbase').doc(accountUUID()).update("account", this.userdb.account)
    },
    dateToStringDDMMYYYY(x) {
      return dateToStringDDMMYYYY(x)
    },
    contractDetails1() {
      return "AKTIV bis "+dateToStringDDMMYYYY(this.userdb.account.paiduntil)
    },
    contractDetails2() {
      var ret = "danach Verlängerung um " 
      if (this.userdb.account.period == "MONTH") {
          ret += "1 Monat " 
      }
      else {
          ret += "12 Monate " 
      }
      return ret
    },
    getAccVal(x,y) {
      return getAccountValues(x,y)
    },
    getTerm(term) {
      return getTerm(term)
    },

    downloadbill(billdata){
      console.log(billdata.id)
      this.bill = new Bill()
      this.bill.setAdress(billdata.account.adress1,billdata.account.adress2,billdata.account.adress3,billdata.account.adress4)
      this.bill.setDate(dateToStringDDMMYYYY(billdata.date),dateToStringDDMMYYYY(billdata.date+1000 * 60 * 60 * 24 * getConstant('billpayperiod')))
      this.bill.setBillNr(billdata.billnumber)
      this.bill.setItem(billdata.item1text,billdata.item1price)
      var doc = this.bill.createpdf();
      doc.save("Rechnung_"+dateToStringDDMMYYYY(billdata.date)+".pdf")
    },
    newPassword(){
      console.log("gdfgdf")
      resetPassword(this.userdb.account.useruuid)
      this.snackbartext = "Email zur Neuvergabe des Passworts wurde an " +this.userdb.account.useruuid+ " gesendet."
      this.snackbarcolor = "success"
      this.snackbar = true
    }

  },

};
</script>
