import jsPDF from 'jspdf'
export default class Sign {
  constructor() {
    this.title = "";
    this.text = "";
    this.link = "";
    this.ref = "";
    this.imgData = ""

  }

  setData(ti, te, li, na) {
    this.title = ti;
    this.text = te;
    this.link = li;
    this.name = na;
  }

  setCode(idata){
    this.imgData = idata;
  }

  drawRoundRect(linewidth, rad, x,y,w,h) {
    doc.circle(120, 20, 5, 'FD');
  }

  createpdf() {
    // A4 297 x 210
    var a4h = 297
    var a4w = 210

    // https://mrrio.github.io/
    var doc = new jsPDF();
    var bordertext = 30

    var floattextwidth = 320
    doc.setLineWidth(5)

    doc.roundedRect(15, 15, a4w-30, a4h-30,30,30)
    
    // TITEL
    doc.setFont("helvetica", "bold").setFontSize(34);
    doc.text(a4w/2, 50 , this.title, 'center');

    // TEXT
    // text splitten so dasss es passt
    var newtext=""
    const textparts = this.text.split('\n');
    for (var i=0;i<textparts.length; i++) {
      if (doc.getTextWidth(textparts[i])>floattextwidth) {
        const lineparts = textparts[i].split(' ');
        var newline = lineparts[0]
        for (var j=1;j<lineparts.length; j++) {
          var trial = newline + " " + lineparts[j]
          if (doc.getTextWidth(trial)>floattextwidth) {
            newtext = newtext + "\n" + newline
            newline= lineparts[j]
          }
          else {
            newline= newline + " " + lineparts[j]
          }
        }
        newtext = newtext + "\n" + newline
      }
      else {
        newtext = newtext + "\n" + textparts[i]
      }
    }

    doc.setFont("helvetica", "bold").setFontSize(16);
    doc.text(bordertext, 55 , newtext, 'left');

    //QRCODE
    // https://davidshimjs.github.io/qrcodejs/
    // https://www.npmjs.com/package/qrcode
    var QRCode = require('qrcode')

    QRCode.toDataURL(this.link, function (err, url) {
    //    console.log(url)
    //  var imgData = atob(url)
        var qrsize =125
        doc.addImage(url, 'JPEG', a4w/2-qrsize/2, 130, qrsize, qrsize);
    })

    // LOGO
    doc.setFont("helvetica", "bold").setFontSize(20);
    doc.text(a4w-40,277 , "crowdalerts.net", 'right');
    
    // Alarmname
    doc.setFont("helvetica", "bold").setFontSize(6);
    doc.text(40,277 , this.name, 'left');


    return doc
  }

}

