<template>
  <v-container class="fill-height" fluid>
  <v-snackbar v-model="snackbar" :timeout="5000" top :color=snackbarcolor>{{snackbartext}} <v-btn icon @click="snackbar=false"><v-icon>mdi-close</v-icon></v-btn></v-snackbar>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat >
            <v-toolbar-title>Login</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                label="Login"
                name="login"
                prepend-icon="mdi-account"
                type="text"
                v-model="email"
              ></v-text-field>

              <v-text-field
                id="password"
                label="Password"
                name="password"
                prepend-icon="mdi-lock"
                type="password"
                v-model="password"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="normal" @click="$router.push('resetPassword')">Passwort vergessen</v-btn>
            <v-btn color="primary" @click="login">Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>


import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import router from '../router'

export default {
  name: "login",
  data: () => ({
    snackbar: false,
    snackbartext: "",
    snackbarcolor: "",
    email: "",
    password: "",

  }),
  methods: {
    login: function() {
      var self = this;
      firebase.auth().signInWithEmailAndPassword(this.email, this.password).then(
        function() {
          router.push('home');
        },
        function(err) {
          // https://firebase.google.com/docs/reference/js/firebase.auth.Auth
          if (err.code == "auth/too-many-requests") {
              self.snackbartext = "Zu viele Versuche. Bitte probieren Sie es später nochmal"
          }
          else if (err.code == "auth/wrong-password") {
              self.snackbartext = "Falsches Passwort"
          }
          else if (err.code == "auth/user-not-found") {
              self.snackbartext = "Account exitsiert nicht"
          }
          else {
              self.snackbartext = "ERROR: " + err.message+ "\ncode: "+ err.code
              console.log(err.code)
          }
          self.snackbarcolor = "error"
          self.snackbar = true
        }
      );
    }
  }
};
</script>

