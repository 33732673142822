

<template>
<div>
    logging out
</div>
</template>

<script>

import { user_logout } from "../user.js";
import router from "../router";

export default {
    created(){
        user_logout();
        setTimeout( () => this.$router.push({ path: '/home'}), 1000);

    },
  }

</script>
