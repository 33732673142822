<template>
  <div>
<v-btn color="grey" dark class="ma-2" @click="pdftest()">TEST</v-btn>
<v-btn color="grey" dark class="ma-2" @click="billtest()">BILL</v-btn>
<v-btn color="grey" dark class="ma-2" @click="dbtest()">DBTEST</v-btn>
<v-btn color="grey" dark class="ma-2" @click="test2()">test2</v-btn>
<v-btn color="grey" dark class="ma-2" @click="excel()">Excel</v-btn>
<br>

<v-text-field label="Time" prepend-icon="mdi-account" type="text" v-model="timein" @change="calctime()"></v-text-field>
{{timeout}}
<br>


Jetzt: {{datenow(0)}}<br>
+2min: {{datenow(2)}}<br>
+1h: {{datenow(60)}}<br>
+1d: {{datenow(60*24)}}<br>
+1y: {{datenow(60*24*365)}}<br>
-1d: {{datenow(-60*24)}}<br>
-90m: {{datenow(-90)}}<br>

Weeknr: {{getWeekNumber()}} <br><br>

{{dateToStringFLEX(1604522129586)}}<br>
{{dateToStringFLEX(datenow(-90))}}<br>
{{dateToStringFLEX(datenow(4))}}<br>
{{dateToStringFLEX(datenow(4))}}<br>
<br>
{{dateToStringDDMMYYYY(datenow(0))}}<br>

<!--
    <v-form >
      <v-text-field label="Name" prepend-icon="mdi-account" type="text" v-model="userdb.account.username" @change="save()"></v-text-field>
      <v-text-field label="Login" readonly prepend-icon="mdi-account" type="text" v-model="userdb.account.useruuid"></v-text-field>
      <v-btn color="grey" dark class="mb-2" >Passwort ändern TODO</v-btn>
      <v-text-field label="Account typ" readonly prepend-icon="mdi-account" type="text" v-model="userdb.account.accounttype"></v-text-field>
      <v-btn color="primary" class="mb-2" @click="$router.push('accounttype')" >Accounttyp ändern</v-btn>
    </v-form>
-->





  </div>


  
</template>



<script>
import { db } from "../firebase";
import { accountUUID } from "../user.js";
import { accountName } from "../user.js";
import { dateToStringDDMMYYYY } from "../helpers.js";
import { dateToStringFLEX } from "../helpers.js";
import { messageCount } from "../helpers.js";
import { createpdf } from "../pdfbill.js";
import Bill from "../pdfbill.js";
import { interal_site_login_check } from "../user.js";
import router from '../router';
import jsPDF from 'jspdf' 
import * as XLSX from 'xlsx/xlsx.mjs';
//import { read, utils, writeFileXLSX } from 'xlsx';


export default {
  name: "test",
  data() {
    return {
      currentaccountadditionltext: "",
      bill: null,
      timein: 333,
      timeout: "333",
      testtest: false,
      animals: [
                  {"name": "cat", "category": "animal"}
                  ,{"name": "dog", "category": "animal"}
                  ,{"name": "pig", "category": "animal"}
                ],
      pokemons: [
                  {"name": "pikachu", "category": "pokemon"}
                  ,{"name": "Arbok", "category": "pokemon"}
                  ,{"name": "Eevee", "category": "pokemon"}
                ]    
      }


  },
  firestore: {
    testtest: db.collectionGroup('targets').where('chatid', '==', "1816713774")
  },


  methods: {
    accountName: function() {
      return accountName();
    },
    excel() {
      // export json to Worksheet of Excel
      // only array possible
      var animalWS = XLSX.utils.json_to_sheet(this.animals) 
      var pokemonWS = XLSX.utils.json_to_sheet(this.pokemons) 

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new() // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, animalWS, 'animals') // sheetAName is name of Worksheet
      XLSX.utils.book_append_sheet(wb, pokemonWS, 'pokemons')   

      // export Excel file
      XLSX.writeFile(wb, 'book.xlsx') // name of the file is 'book.xlsx'      


    },
    pdftest() {
      this.bill = new Bill()
      this.bill.setAdress("Gemeinde Hintertupfingen","z.Hd. Heiner Meier","Hauptplatz 45", "89453 Hintertupfingen")
      this.bill.setDate("27.10.2020","14.11.2020")
      this.bill.setBillNr("55435345")
      this.bill.setItem("Das Schlüsselwort static definiert statische Methoden.\nStatische Methoden werden ohne Instanzierung einer Klasse aufgerufen", 20)
      var doc = this.bill.createpdf();
      doc.save("test.pdf")


    },
    billtest() {
      db.collection('userbase').doc('ghBFQpIEXwclQ4xnqJZLBnsrCa52').collection('targets').doc('QfpCLN8JDNDfKzTDGdVp').collection('alerts').where('triggercode','==','Me6yzZyk').get()
        .then(function(snapshot)  { 
            console.log(snapshot.size)
            snapshot.forEach(function(doc) {
              console.log(doc.data().date)
            })


      })
    },
    dbtest() {
      var now = new Date()
      db.collection('userbase').where('account.paiduntil','<',now.getTime()).get().then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
              console.log(doc.id)
              var docdata = doc.data()
              console.log(docdata.account.paiduntil)

            var enddate = new Date(docdata.account.paiduntil)
            if (docdata.account.period == "YEAR") {
                enddate.setFullYear(enddate.getFullYear() + 1);
            }
            else {
                enddate.setMonth(enddate.getMonth() + 1);
            }

            db.collection('ztmp_createbill').add({
              userid : doc.id,
              startdate : docdata.account.paiduntil, 
              enddate: enddate.getTime(),
              period: docdata.account.period,
              date: Date.now(),
              account: docdata.account
            })
            docdata.account.paiduntil = enddate.getTime()

            db.collection('userbase').doc(doc.id).update("account", docdata.account)

          });
      });
    },
    datenow(x) {
      var d = new Date()
      return d.getTime()+x*1000*60
    },

    getWeekNumber() {
        var d = new Date();
        var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));

        var passeddays = Math.floor(((d - yearStart) / 86400000))  // 1.1. ---> Tag 0
        // 4.1. muss immer KW1 sein
        var weeknr = Math.floor((passeddays+4)/7)
        return weeknr
    },

    calctime() {
    console.log("fsdfd "+parseInt(this.timein))

    var x = new Date(parseInt(this.timein))
    console.log("fsdfd "+x)
    this.timeout = x
    },
    dateToStringFLEX(x) {
      return dateToStringFLEX(x)
    },
    dateToStringDDMMYYYY(x) {
      return dateToStringDDMMYYYY(x)
    },
    test2(){
        var userid = "adasd"
        var d = new Date()
        var mon = d.getMonth()+1
        var y = d.getFullYear()

        db.collection('statistics').doc(userid).get().then((docSnapshot) => {
            if (docSnapshot.exists) {
            } else {
               db.collection('statistics').doc(userid).set({created: true});
              
            }
        });

        db.collection('statistics').doc(userid).collection('messages').doc(y+"_"+mon).get().then((docSnapshot) => {
            if (docSnapshot.exists) {
              db.collection('statistics').doc(userid).collection('messages').doc(y+"_"+mon).update({count: docSnapshot.data().count+1});

            } else {
               db.collection('statistics').doc(userid).collection('messages').doc(y+"_"+mon).set({count: 1});
              
            }
        });

        
/*
        db.collection('statistics').doc(userid).collection('messages').doc(y+"_"+mon).get().then((xxx) => {
            let ccc = xxx.data().count ++
            if (ccc==1) {
              db.collection('statistics').doc(userid).collection('messages').add(y+"_"+mon).set("count", ccc)
            }
            else {
              db.collection('statistics').doc(userid).collection('messages').doc(y+"_"+mon).update("count", ccc)
            }
          })
          .catch(err => {
            db.collection('statistics').doc(userid).collection('messages').doc(y+"_"+mon).update("count", 0)
          });
          */
    }
  },

};
</script>


