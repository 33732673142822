import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Aboutus from '../views/Aboutus.vue'
import Licenses from '../views/licenses.vue'
import Privacy from '../views/Privacy.vue'
import Terms from '../views/Terms.vue'
import Login from '../views/Login.vue'
import Admin from '../views/admin.vue'
import ResetPassword from '../views/ResetPassword.vue'
import Logout from '../views/Logout.vue'
import SignUp from '../views/SignUp.vue'
import alerts from '../views/alerts.vue'
import alertedit from '../views/alertedit.vue'
import alertsdesign from '../views/alertsdesign.vue'
import targets from '../views/targets.vue'
import targetedit from '../views/targetedit.vue'
import user from '../views/user.vue'
import accounttype from '../views/accounttype.vue'
import scanlander from '../views/scanlander.vue'
import bill from '../views/bill.vue'
import sign_test from '../views/sign_test.vue'
import list from '../views/list.vue'
import verify from '../views/verify.vue'
import displaytest from '../views/displaytest.vue'
import test from '../views/test.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    alias: '/home',
    name: 'home',
    component: Home
  },
/*  {
    path: '/home',
    name: 'home',
    component: Home
  },*/
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin
  },
  {
    path: '/resetpassword',
    name: 'resetpassword',
    component: ResetPassword
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp
  },
  {
    path: '/alerts',
    name: 'alerts',
    component: alerts
  },
  {
    path: '/alertedit',
    name: 'alertedit',
    component: alertedit
  },
  {
    path: '/alertsdesign',
    name: 'alertsdesign',
    component: alertsdesign
  },
  {
    path: '/targets',
    name: 'targets',
    component: targets
  },
  {
    path: '/targetedit',
    name: 'targetedit',
    component: targetedit
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    component: Aboutus
  },
  {
    path: '/licenses',
    name: 'licenses',
    component: Licenses
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: Privacy
  },
  {
    path: '/terms',
    name: 'terms',
    component: Terms
  },
  {
    path: '/user',
    name: 'user',
    component: user
  },
  {
    path: '/accounttype',
    name: 'accounttype',
    component: accounttype
  },
  {
    path: '/a/:trigger',
    name: 'scanlander',
    component: scanlander,
  },
  {
    path: '/bill/:trigger',
    name: 'bill',
    component: bill
  },
  {
    path: '/sign/',
    name: 'sign',
    component: sign_test
  },
  {
    path: '/list',
    name: 'list',
    component: list
  },
  {
    path: '/verify/:userid/:targetid/:hash',
    name: 'verify',
    component: verify
  },
  {
    path: '/displaytest',
    name: 'displaytest',
    component: displaytest
  },
  {
    path: '/test',
    name: 'test',
    component: test
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
