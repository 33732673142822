import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import VueTour from 'vue-tour'

require('vue-tour/dist/vue-tour.css')

import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";

Vue.config.productionTip = false

Vue.use(GlobalComponents);
Vue.use(GlobalDirectives);
Vue.use(VueTour)

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
