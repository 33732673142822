<template>
  <div class="accounttype">
  <v-snackbar v-model="snackbar" :timeout="5000" top :color=snackbarcolor>{{snackbartext}} <v-btn icon @click="snackbar=false"><v-icon>mdi-close</v-icon></v-btn></v-snackbar>  
    <v-toolbar flat color="white">
      <v-toolbar-title>Vertrag</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card elevation="2" outlined>
      <v-card-title>Übersicht </v-card-title>
      <v-card-text>
        <v-simple-table fixed-header>
          <thead>
            <tr>
                <th class="text-left"></th>
                <th class="text-left"><h2>VERWENDET</h2></th>
                <th v-for="a in accShow" :key="a.id" class="text-left"><h2>{{a.name}}</h2></th>
            </tr>
          </thead>
         <tbody>
            <tr>
              <td></td>
              <td><i>derzeit Verwendete Anzahl</i></td> 
              <td v-for="a in accShow" :key="a.id">{{a.comment}}</td>
            </tr>
            <tr>
              <td>Anzahl Alarme</td>
              <td>{{getStatsVal("maxAlarm")}}</td> 
              <td v-for="a in accShow" :key="a.id">{{a.maxAlarm}}</td>
            </tr>
            <tr>
              <td>Anzahl Ziele</td>
              <td>{{getStatsVal("maxTargets")}}</td> 
              <td v-for="a in accShow" :key="a.id">{{a.maxTargets}}</td>
            </tr>
            <tr>
              <td>Anzahl Benachrichtigungen pro Monat</td>
              <td>  Diesen Monat: {{getStatsVal("usedNotificationsThis")}} <br>Letzten Monat: {{getStatsVal("usedNotificationsLast")}}</td> 
              <td v-for="a in accShow" :key="a.id">{{a.maxNotifications}}</td>
            </tr>
            <tr>
              <td>Anzahl Eingabefelder</td>
              <td>{{getStatsVal("maxInputfields")}}</td> 
              <td v-for="a in accShow" :key="a.id">{{a.maxInputfields}}</td>
            </tr>
            <tr>
              <td>Eigenes Alarmdesign<br><i>Kunde sieht Alarmseite mit Ihrer Farbgebung und Link zu Ihrer Webseite</i></td>
              <td></td>
              <td v-for="a in accShow" :key="a.id"><v-icon v-if="!a.owndesign" color="red"> mdi-close-circle</v-icon><v-icon v-else color="green">mdi-check-circle</v-icon></td>
            </tr>
       

            <tr>
              <td>Preis pro Monat (ohne MwSt.)</td>
              <td></td>
              <td v-for="a in accShow" :key="a.id">{{a.price}} &euro;</td>
            </tr>

          </tbody> 
        </v-simple-table>
      </v-card-text>
    </v-card> <br>
    <v-card elevation="2" outlined v-if="getAccVal(userdb.account.accounttype, 'level')>99">
      <v-card-title>Ihr Vertrag </v-card-title>
      <v-card-text >
        Ihre Vertragsart ist {{getAccVal(userdb.account.accounttype, 'name') }} bis zum {{dateToStringDDMMYYYY(userdb.account.paiduntil)}}.<br>

        <div v-if="getAccVal(userdb.account.accountnext, 'level') > 100"> Danach Verlängerung um {{getTerm(userdb.account.period)}} in der Vetragsart {{getAccVal(userdb.account.accountnext, 'name')}} </div>
        <div v-if="userdb.account.accountnext == 'NONE'" class="red--text"><v-icon color="red">mdi-alert</v-icon> Am {{dateToStringDDMMYYYY(userdb.account.paiduntil)}} wird der Account stillgelegt !!</div>
        <div v-if="accountnextInterim == 'NOTCHOOSEN'" class="red--text"><v-icon color="red">mdi-alert</v-icon> Bitte wählen Sie eine Vertagsart!</div>

         <v-radio-group v-model="accountnextInterim" row @change="saveAccountnext()">
           Nächste Vertragsart ab {{dateToStringDDMMYYYY(userdb.account.paiduntil)}}: 
            <v-radio v-for="a in accChoice" :key="a.id" :label="a.name" :value="a.id"></v-radio>
            <v-radio label="keine Verlängerung" value="NONE"></v-radio>
        </v-radio-group>

  <!-- DOWNGRADE   <v-btn v-if="getAccVal(userdb.account.accountnext, 'level') < getAccVal(userdb.account.accounttype, 'level')" color="primary" id="alerts-add" dark class="mb-2" @click="upgradeNow()">Sofort downgraden</v-btn> -->

        <div v-if="(userdb.account.period=='NOTCHOOSEN') &&(accountnextInterim!='NONE')" class="red--text"><v-icon color="red">mdi-alert</v-icon> Bitte wählen Sie eine Vertagsdauer!</div>
        <v-radio-group v-model="userdb.account.period" row @change="save()">
           Nächste Verlängerung ab {{dateToStringDDMMYYYY(userdb.account.paiduntil)}}: 
          <v-radio label="Monat" value="MONTH"></v-radio>
          <v-radio label="Jahr" value="YEAR"></v-radio>
        </v-radio-group>

        <v-btn v-if="(getAccVal(userdb.account.accountnext, 'level') > getAccVal(userdb.account.accounttype, 'level'))&&(userdb.account.accountnext==accountnextInterim)" color="primary" id="alerts-add" dark class="mb-2" 
          @click="upgradeNow()">Sofort upgraden auf  {{getAccVal(userdb.account.accountnext, 'name')}}</v-btn>
        <v-btn v-if="(userdb.account.accountnext!=accountnextInterim)&&(accountnextInterim!='NONE')&&
          (userdb.account.period!='NOTCHOOSEN')&&(getAccVal(accountnextInterim, 'level') > getAccVal(userdb.account.accounttype, 'level'))" 
          color="primary" id="alerts-add" dark class="mb-2" @click="order()"> Kostenpflichtig bestellen</v-btn>
        <v-btn v-if="(userdb.account.accountnext!=accountnextInterim)&&(accountnextInterim!='NONE')&&
          (userdb.account.period!='NOTCHOOSEN')&&(getAccVal(accountnextInterim, 'level') < getAccVal(userdb.account.accounttype, 'level'))" 
          color="primary" id="alerts-add" dark class="mb-2" @click="order()"> Ab {{dateToStringDDMMYYYY(userdb.account.paiduntil)}} downgrade auf {{getAccVal(accountnextInterim, 'name')}}</v-btn>
        <v-btn v-if="(userdb.account.accountnext!=accountnextInterim)&&(userdb.account.accounttype==accountnextInterim)&&(userdb.account.period!='NOTCHOOSEN')" 
          color="primary" id="alerts-add" dark class="mb-2" @click="order()"> Bei {{getAccVal(accountnextInterim, 'name')}} bleiben</v-btn>
        <v-btn v-if="(userdb.account.accountnext!=accountnextInterim)&&(accountnextInterim=='NONE')" color="primary" id="alerts-add" dark class="mb-2" @click="order()">
          Abbestellen</v-btn>

      </v-card-text>
    </v-card>
    <v-card elevation="2" outlined v-else> 
      <v-card-title>Ihr Vertrag </v-card-title>
      <v-card-text >
        Ihre Vertragsart ist {{getAccVal(userdb.account.accounttype, 'name') }}.<br>
      </v-card-text>    
    </v-card>

  </div>
</template>

<script>
import { db } from "../firebase";
import { accountUUID } from "../user.js";
import { accountName } from "../user.js";
import { dateToStringDDMMYYYY, countstring } from "../helpers.js";
import { getTerm } from "../helpers.js";
import { getConstant } from "../constants.js";
import { getAccounts } from "../constants.js";
import { getAccountValues } from "../constants.js";
import { interal_site_login_check } from "../user.js";

export default {
  name: "accounttype",
  data() {
    return {
      userdb: {account: {}},
      accShow: [], // alle gezeigten verträge
      accChoice: [], // die wählbaren verträge
      userstats: {notcount: {}},
      usedInputfields: 0,
      accountnextInterim: "",
      snackbar: false,
      snackbartext: "",
      snackbarcolor: "",
    }
  },
  created() {
    interal_site_login_check()
  },
  firestore() {
    return {
        userdb: db.collection('userbase').doc(accountUUID())   ,    
        userstats: db.collection('statistics').doc(accountUUID())   ,    
    };
  },
  mounted(){ 
    // used input fields ermitteln
    db.collection('userbase').doc(accountUUID()).collection('alerts').get().then(snapshot => { 
      if (snapshot.length==0) {
        console.log('triggercode nicht gefunden: '+triggercode);
      }
      snapshot.forEach(thealert => {  // foreach == für den einen   thealert = der getriggerte alert
          if (thealert.data().inputfields.length > this.usedInputfields) {
            this.usedInputfields = thealert.data().inputfields.length
          }
        })
      })

    var acc = getAccounts()
    if (this.accShow==0) {
      acc.forEach(element => {
        if (element.level > 99) {
          this.accShow.push(element)
        }
      });
    }
    if (this.accChoice==0) {
      acc.forEach(element => {
        if (element.level > 100) {
          this.accChoice.push(element)
        }
      });
    }

    db.collection('userbase').doc(accountUUID()).get().then((docSnapshot) => {
        if (docSnapshot.exists) {
          this.accountnextInterim = docSnapshot.data().account.accountnext
        } 
    })
    

  },
  methods: {
    accountName: function() {
      return accountName();
    },
    save() {
      db.collection('userbase').doc(accountUUID()).update("account", {})
  //    db.collection('userbase').doc(accountUUID()).set({account: []}, {merge: true})
      db.collection('userbase').doc(accountUUID()).update("account", this.userdb.account)
    },
    getTerm(term) {
      return getTerm(term)
    },
    getAccVal(x,y) {
      return getAccountValues(x,y)
    },
    getStatsVal(type) {
      if (type =="maxAlarm") {
        return this.userstats.usedAlerts
      }
      if (type =="maxTargets") {
        return this.userstats.usedTargets
      }
      if (type =="usedNotificationsThis") {
        if (countstring(false) in this.userstats.notcount) {
          return this.userstats.notcount[countstring(false)]
        }
        else {
          return 0
        }
      }
      if (type =="usedNotificationsLast") {
        if (countstring(true) in this.userstats.notcount) {
          return this.userstats.notcount[countstring(true)]
        }
        else {
          return 0
        }
      }
      if (type =="maxInputfields") {
        return this.usedInputfields
      }
      return "not known"
    },      
    saveAccountnext() {
      console.log("saveAccountnext")
      if (this.userdb.account.created + getConstant("testperiod") * 1000*60*60*24 > Date.now() ) { // noch im Testzeitraum
        if (this.getAccVal(this.userdb.account.accountnext, 'level') > 100 ) {
          this.userdb.account.accounttype = this.userdb.account.accountnext
          // TODO POPUP mit AGB usw AGB --> userdata
          this.snackbartext = "Die Zeit bis zum Ende der "+getConstant('testperiod')+" tägigen Testzeit ist auch in der neuen Vertagsart kostenlos."
          this.snackbarcolor = "success"
          this.snackbar = true
        }
      }
      this.save()
    },
    upgradeNow() {
      // Upgrade durch zeitenanrechnung, keine Rechnung sofort
      var restzeit = this.userdb.account.paiduntil - Date.now()
      restzeit = restzeit * this.getAccVal(this.userdb.account.accounttype, 'price') / this.getAccVal(this.userdb.account.accountnext, 'price')

      this.userdb.account.paiduntil = Date.now() + restzeit
      this.userdb.account.accounttype = this.userdb.account.accountnext

      this.snackbartext = "Die schon bezahlte Restzeit in der alten Vertragsart wird anteilmäßig angerechnet."
      this.snackbarcolor = "success"
      this.snackbar = true


      this.save()

    },
    order() {
      this.userdb.account.accountnext = this.accountnextInterim
      this.saveAccountnext()
    },
    dateToStringDDMMYYYY(x) {
      return dateToStringDDMMYYYY(x)
    }
  },
};
</script>
