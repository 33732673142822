<template>
  <div>
  <v-snackbar v-model="snackbar" :timeout="5000" top :color=snackbarcolor>{{snackbartext}} <v-btn icon @click="snackbar=false"><v-icon>mdi-close</v-icon></v-btn></v-snackbar>
    <v-toolbar flat color="white">
      <v-toolbar-title>Benachrichtigungsziele<v-icon>mdi-account-arrow-left</v-icon></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="primary" id="targets-add" dark class="mb-2" @click="newtarget()">Neues Benachrichtigungsziel</v-btn>
    </v-toolbar>
    <v-data-table :headers="headers" :items="users_targets" class="elevation-3">
      <template v-slot:[`item.targettitle`]="{item}" >
        <div id="targets-preset"> {{ item.targettitle }} </div>
      </template>   
      <template v-slot:[`item.type`]="{item}">
        <v-icon >{{getIcon(item.type)}}</v-icon> {{getLabel(item.type)}}
      </template>   
      <template v-slot:[`item.adress`]="{item}">
        <span v-show="!isVerified(item.type,item.adress, item.hash)" class="red--text">
        <v-icon color="red">mdi-alert</v-icon> ZIEL NICHT BESTÄTIGT</span> 
        {{ item.adress }} 
      </template>   
      <template v-slot:[`item.opt`]="{item}">
        <v-btn id="targets-edit" @click="edittarget(item.id)" target="_blank" text ><v-icon>mdi-pencil</v-icon></v-btn>
        <v-btn @click="deletetarget(item.id)" target="_blank" text ><v-icon>mdi-trash-can</v-icon></v-btn>
        <v-btn v-if="item.type=='crowdalertslist'" @click="visittargetCrowdaleretsList(item.id)" target="_blank" text ><v-icon>mdi-forward</v-icon></v-btn>
      </template>   
    </v-data-table>
    <br>
    
    <div v-show="users_targets_del.length>0">
      Gelöschte Benachrichtigungsziele (werden nach 30 Tagen automatisch endgültig gelöscht)
      <v-data-table :headers="headers_del" :items="users_targets_del" class="elevation-3">
        <template v-slot:[`item.targettitle`]="{item}">
          {{ item.targettitle }}
        </template>   
        <template v-slot:[`item.type`]="{item}">
          <v-icon >{{getIcon(item.type)}}</v-icon> {{getLabel(item.type)}}
        </template>   
        <template v-slot:[`item.deletedate`]="{item}">
          {{dateToStringFLEX(item.deletedate)}}
        </template>   
      <template v-slot:[`item.opt`]="{item}">
          <v-btn @click="undeletetarget(item.id)" target="_blank" text ><v-icon>mdi-undo-variant</v-icon></v-btn>
      </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { db } from "../firebase";
import { accountUUID } from "../user.js";
import router from '../router'
import { interal_site_login_check } from "../user.js";
import { getIconFromType } from "../helpers.js";
import { getTypeLabelFromType } from "../helpers.js";
import { dateToStringFLEX } from "../helpers.js";
import { isVerified } from "../helpers.js";
import { getAccountValues } from "../constants.js";

export default {
  name: 'targets',
  data: () => ({
    users_targets: [],
    users_targets_del: [],
    headers: [
      {text: 'Name', value: 'targettitle', align: 'left'},
      {text: 'Typ', value: 'type', align: 'left'},
      {text: 'Adresse', value: 'adress', align: 'left'},
      { text: '', sortable: false , value: 'opt'}],
    headers_del: [
      {text: 'Name', value: 'targettitle', align: 'left'},
      {text: 'Typ', value: 'type', align: 'left'},
      {text: 'Löschdatum', value: 'deletedate', align: 'left'},
      { text: 'Wiederherstellen', value: 'opt', sortable: false }],
    userdb: {},
    snackbar: false,
    snackbartext: "",
    snackbarcolor: "",
  }),
  firestore: {
    users_targets: db.collection('userbase').doc("null").collection('targets').where('deleted', '==', false),
    userdb: db.collection('userbase').doc(accountUUID()),
  },
  components: {
  },
  created(){
    interal_site_login_check()
  },

  mounted(){
    this.$bind('users_targets',db.collection('userbase').doc(accountUUID()).collection('targets').where('deleted', '==', false))
    this.$bind('users_targets_del',db.collection('userbase').doc(accountUUID()).collection('targets').where('deleted', '==', true))
    this.$bind('userdb',db.collection('userbase').doc(accountUUID()))
//    this.userdb = db.collection('userbase').doc(accountUUID())
  },
  updated() {
    this.purgeOld()
  },

    methods: {
    blablub() {
      router.push({ name: 'targetedit', params: { targetId: '123' } })
    },
    newtarget() {
      var maxnumb = getAccountValues(this.userdb.account.accounttype, "maxTargets")

      if (this.users_targets.length >= maxnumb) {
//        console.log("too much alerts")
        this.snackbartext = "Maximalzahl der Ziele im Vertrag "+getAccountValues(this.userdb.account.accounttype, "name")+" erreicht. Bitte ändern Sie den Vertrag in EINSTELLUNGEN"
        this.snackbarcolor = "error"
        this.snackbar = true
      }
      else {
        db.collection('statistics').doc(accountUUID()).update("usedTargets", this.users_targets.length+1)
        db.collection('userbase').doc(accountUUID()).collection('targets').add({type: "new", deleted: false}).then(
          (res) => {      
            router.push({ name: 'targetedit', params: { targetId: res.id,  aim: "new" } })
          });
      }
    },
    edittarget(id) {
      router.push({ name: 'targetedit', params: { targetId: id ,  aim: "edit" } })
    },
    deletetarget(tid)  {
      var thetarget = this.users_targets.find(x => x.id === tid)
      if (thetarget.type == "crowdalertslist") {
        db.collection('userbase').doc(accountUUID()).collection('targets').doc(tid).collection('alerts').get().then((snapshot) => {
          if (snapshot.size>0) {
            this.snackbartext = "Listen können nur gelöscht werden, wenn sie leer sind. Bitte Liste zuerst leeren"
            this.snackbarcolor = "error"
            this.snackbar = true

          }
          else {
            db.collection('statistics').doc(accountUUID()).update("usedTargets", this.users_targets.length-1)
            db.collection('userbase').doc(accountUUID()).collection('targets').doc(tid).update({deleted: true, deletedate: Date.now()})
          }
        } )
      }
      else {
        db.collection('statistics').doc(accountUUID()).update("usedTargets", this.users_targets.length-1)
        db.collection('userbase').doc(accountUUID()).collection('targets').doc(tid).update({deleted: true, deletedate: Date.now()})
      }
    },
    undeletetarget(id)  {
      var maxnumb = getAccountValues(this.userdb.account.accounttype, "maxTargets")
      if (this.users_targets.length >= maxnumb) {
        console.log("too much alerts")
        this.snackbartext = "Maximalzahl der Ziele im Vertrag "+getAccountValues(this.userdb.account.accounttype, "name")+" erreicht. Bitte ändern Sie den Vertrag in EINSTELLUNGEN"
        this.snackbarcolor = "error"
        this.snackbar = true
      }
      else {
        db.collection('statistics').doc(accountUUID()).update("usedTargets", this.users_targets.length+1)
        db.collection('userbase').doc(accountUUID()).collection('targets').doc(id).update("deleted", false)
      }
    },

    purgeOld(){
      var deletebefore30d = Date.now() - 1000 * 60 * 60 * 24 * 30 // älter als einen Monat
      this.users_targets_del.forEach(function(item) {
        if (item.deletedate<deletebefore30d) {
          console.log("xxxx")
          db.collection('userbase').doc(accountUUID()).collection('targets').doc(item.id).delete()
        }
      })
    },

    dateToStringFLEX(x) {
      return  dateToStringFLEX(x)
    },
    visittargetCrowdaleretsList(id)  {
      router.push({ name: 'list', params: { targetid: id} })
    },
    getIcon(type) {
      return getIconFromType(type)
    },
    isVerified(type, adress, hash) {
      return isVerified(type, adress, hash)
    },
    getVerifiedIcon(plain,hash) {
      if (funhash(plain)==hash) {
        return getIconFromType("verified")
      }
      return getIconFromType("notverified")    
    },
    getLabel(type) {
      return getTypeLabelFromType(type)
    },
  }
}

</script>
