

<template>
  <div class="bill">
    {{usertext}}  
  </div>
</template>

<script>

import { dateToStringDDMMYYYY } from "../helpers.js";
import Bill from "../pdfbill.js";
import { db } from "../firebase";

export default {
  name: 'bill',
    data: () => ({
    usertext: "Bitte warten",
    triggered: false
  }),
  mounted(){
    db.collection('ztmp_createbill').doc(this.$route.params.trigger).get().then((pa) => { 
      if (pa.exists) {
        // TODO verschieben in js file und auch von Einstellungen aus verwenden
        this.bill = new Bill(pa.data())
        var doc = this.bill.createpdf();
        doc.save("Rechnung_"+dateToStringDDMMYYYY(pa.data().date)+".pdf")
        this.usertext = "Rechnung generiert"
      }
      else {
         this.usertext  = "Link nicht mehr gültig."
      }
    })
    .catch((err) => {
      this.usertext = "Fehler: "+err
    }
        
    )
  },
}

</script>
