<template>
  <div class="about">


<div class='impressum'><h1>Impressum</h1><p>Angaben gemäß §5 TMG</p>

<p>
<b>crowdalerts.net</b><br> 
Jochen Bauknecht <br> 
Mozartstr. 14<br> 
73269 Hochdorf <br> 

</p><p> <strong>Vertreten durch: </strong><br>
Jochen Bauknecht<br>
</p><p><strong>Kontakt:</strong> <br>
Telefon: 0178 1986532<br>
E-Mail: <a href='mailto:info@crowdalerts.net'>info@crowdalerts.net</a></p>

<p><strong>Umsatzsteuer-ID: </strong> <br>
Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz: DE293135362<br><br>

</p>

<p><strong>Version: </strong> <br>
{{version()}}<br><br>

<p><strong>Verwendete Software: </strong> <br>
<a href='licenses'>Verwendete Software und Lizenzen anzeigen.</a></p>


 </div>
 

 </div>
 
</template>

<script>
import {getBuildInfo} from 'vue-cli-plugin-build-info/plugin'

export default {
  name: 'aboutus',
  methods: {
    version() {
      const date = new Date(getBuildInfo().TIMESTAMP);
      var monat = date.getMonth()+1
      if (monat<10) {
        monat = "0"+monat
      }
      var tag = date.getDate()
      if (tag<10) {
        tag = "0"+tag
      }
      return getBuildInfo().VERSION + "." + monat + tag
    },
  }

}

</script>
