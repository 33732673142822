<template>
<div>
  <v-snackbar v-model="snackbar" :timeout="5000" top :color=snackbarcolor>{{snackbartext}} <v-btn icon @click="snackbar=false"><v-icon>mdi-close</v-icon></v-btn></v-snackbar>
  <v-toolbar flat color="white" id="v-step-2" >
    <v-toolbar-title>Alarme<v-icon>mdi-alarm-light</v-icon></v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn color="primary" id="alerts-add" dark class="mb-2" @click="newalert()">Neuer Alarm</v-btn>
  </v-toolbar>

  <v-card elevation="2" outlined>
    <v-card-title>Alarmdesign </v-card-title>
    <v-card-text>
      <v-btn  color="primary" id="alerts-add" dark class="mb-2" @click="changedesign()">Alarmdesign ändern</v-btn>
    </v-card-text>
  </v-card>
  <br>
  <v-card elevation="2" outlined>
    <v-card-title>Alarme </v-card-title>
    <v-card-text>
      <v-data-table :headers="headers" :items="users_alerts"  class="elevation-3">
        <template v-slot:[`item.alerttitle`]="{item}">
          <div id="alerts-preset">{{ item.alerttitle }}</div>
        </template>
        <template v-slot:[`item.type`]="{item}">
          <div v-if="item.immediate">SOFORT SENDEN</div>
          <div v-if="!item.immediate && item.inputfields.length==0">NUR BUTTON</div>
          <div v-if="!item.immediate && item.inputfields.length>0">Eingabefelder:
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr v-for="infi in item.inputfields" :key="infi.name">
                    {{infi.name}}
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </template>
        <template v-slot:[`item.targets`]="{item}">
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr v-for="tar in item.targets" :key="tar.name">
                  <div v-if="targetexists(tar)">
                    <v-icon>{{gettargetAdressIcon(tar)}}</v-icon>{{gettargetName(tar)}}  {{ gettargetAdress(tar)}}
                      <span v-show="!isVerified(tar)" class="red--text">
                        <v-icon color="red">mdi-alert</v-icon>ZIEL NICHT BESTÄTIGT 
                      </span> 
                  </div>
                </tr>
              </tbody>
              <span v-show="item.targets.length==0" class="red--text">
                <v-icon color="red">mdi-alert</v-icon> KEIN ZIEL DEFINIERT
              </span>
            </template>
          </v-simple-table>
        </template>
        <template v-slot:[`item.opt`]="{item}">
            <v-btn id="alerts-edit" @click="editalert(item.id)" target="_blank" text ><v-icon>mdi-pencil</v-icon></v-btn>
            <v-btn @click="deletealert(item.id, item.triggercode)" target="_blank" text ><v-icon>mdi-trash-can</v-icon></v-btn>
        </template>
      </v-data-table>
      <br>
      <!-- GELÖSCHTE -->
      <div v-show="users_alerts_del.length>0">
        Gelöschte Alarme (werden nach 30 Tagen automatisch endgültig gelöscht)
        <v-data-table :headers="headers_del" :items="users_alerts_del"  class="elevation-3">
          <template v-slot:[`item.alerttitle`]="{item}">
            {{ item.alerttitle }}
          </template>
          <template v-slot:[`item.type`]="{item}">
            <div v-if="item.immediate"> SOFORT </div>
            <div v-if="!item.immediate && item.inputfields.length==0"> NUR BUTTON </div>
            <div v-if="!item.immediate && item.inputfields.length>0"> Eingabefelder:
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr v-for="infi in item.inputfields" :key="infi.name">
                      {{infi.name}}
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </template>
          <template v-slot:[`item.deletedate`]="{item}">
            {{dateToStringFLEX(item.deletedate)}}
          </template>
          <template v-slot:[`item.opt`]="{item}">
            <v-btn @click="undeletealert(item.id, item.triggercode)" target="_blank" text ><v-icon>mdi-undo-variant</v-icon></v-btn>
          </template>
        </v-data-table>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { db } from "../firebase";
import { accountUUID } from "../user.js";
import router from '../router'
import { interal_site_login_check } from "../user.js";
import { getIconFromType } from "../helpers.js";
import { dateToStringFLEX } from "../helpers.js";

import { getTypeLabelFromType } from "../helpers.js";
import { isVerified } from "../helpers.js";
import { getAccountValues } from "../constants.js";

export default {
  name: 'alerts',
  data: () => ({
    users_alerts: [],
    users_alerts_del: [],
    users_targets: [],
    userdb: {},
    snackbar: false,
    snackbartext: "",
    snackbarcolor: "",
    headers: [
      {text: 'Name', value: 'alerttitle', align: 'left'},
      {text: 'Typ', value: 'type', align: 'left'},
      {text: 'Ziele', value: 'targets', align: 'left'},
      { text: '', value: 'opt', sortable: false }
    ],
    headers_del: [
      {text: 'Name', value: 'alerttitle', align: 'left'},
      {text: 'Typ', value: 'type', align: 'left'},
      {text: 'Löschdatum', value: 'deletedate', align: 'left'},
      { text: 'Wiederherstellen', value: 'opt', sortable: false }
    ],
  }),
  firestore: {
    users_alerts: db.collection('userbase').doc("null").collection('alerts').where('deleted', '==', false),
    users_targets: db.collection('userbase').doc("null").collection('targets'),
    userdb: db.collection('userbase').doc(accountUUID()),
  },
  components: {
//      QrcodeVue,
  },
  mounted(){
    this.$bind('users_alerts',db.collection('userbase').doc(accountUUID()).collection('alerts').where('deleted', '==', false))
    this.$bind('users_alerts_del',db.collection('userbase').doc(accountUUID()).collection('alerts').where('deleted', '==', true))
    this.$bind('users_targets',db.collection('userbase').doc(accountUUID()).collection('targets'))
    this.$bind('userdb',db.collection('userbase').doc(accountUUID()))
//    this.userdb = db.collection('userbase').doc(accountUUID())
//    this.$tours['myTour'].nextStep()

    
  },
  created(){
    interal_site_login_check()
  },
  updated() {
    this.purgeOld()
  },
  methods: {
    blablub() {
//      db.collection('email').add({email: "mail@bauki.de", subject: "Hallo", html: "der content"}).then();

//      sendEmail("mail@bauki.de");
//      sendEmail("mail@bauki.de")
//      router.push('alertedit');
//      router.push({ name: 'alertedit', params: { userId: '123' } })
//      this.$refs.editDialog.show();
    console.log("adsdasdsd")
    let museums = db.collectionGroup('alerts').where('triggercode', '==', '1cKX');
    museums.get().then(function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
        console.log("fsdf")
        console.log(doc.id, ' => ', doc.data());

        var usertargets = doc.ref().parent()

        console.log(usertargets);
        
        var ta = doc.data().targets
        ta.forEach(function(item, index) {

              // Hier alle adressen finden und in email collection eintagen
              console.log(item, index);
  //            var thetarget = usertargets.doc(item)
  //            console.log(thetarget.adress)
        });
      });
    });
    },
    targetexists(id) { // gelöschte targets sind noch im alert / publicalert (werden im consumeTriggeredTargets ignoriert)
      var ret = false
      this.users_targets.forEach(function(item) {
        if (item.id == id) {
          ret = true
        }
      });
      return ret 

    },
    gettargetName(id) {
      var ret = "id"
       this.users_targets.forEach(function(item) {
         if (item.id == id) {
           ret=item.targettitle
         }
        });
      return ret //users_targets.doc(id).adress""
    },
    gettargetAdress(id) {
      var ret = "id"
       this.users_targets.forEach(function(item) {
         if (item.id == id) {
           ret=item.adress
         }
        });
      return ret //users_targets.doc(id).adress""
    },
    gettargetAdressIcon(id) {
      var ret = "id"
       this.users_targets.forEach(function(item) {
         if (item.id == id) {
            ret = getIconFromType(item.type)
         }
        });
      return ret //users_targets.doc(id).adress""
    },
    isVerified(id) {
       var ret = false
       this.users_targets.forEach(function(item) {
         if (item.id == id) {
           ret=  isVerified(item.type, item.adress, item.hash)
         }
        });
        return ret
    },
    newalert() {
      var maxnumb = getAccountValues(this.userdb.account.accounttype, "maxAlarm")
      if (this.users_alerts.length >= maxnumb) {
        console.log("too much alerts")
        this.snackbartext = "Maximalzahl der Alarme im Vertrag "+getAccountValues(this.userdb.account.accounttype, "name")+" erreicht. Bitte ändern Sie den Vertrag in EINSTELLUNGEN"
        this.snackbarcolor = "error"
        this.snackbar = true

      }
      else {
        db.collection('statistics').doc(accountUUID()).update("usedAlerts", this.users_alerts.length+1)
        db.collection('userbase').doc(accountUUID()).collection('alerts').add({alerttitle: "", alerttargettext: "", userid: accountUUID(), deleted: false, inputfields:[]}).then(
          (res) => { 
            router.push({ name: 'alertedit', params: { alertId: res.id,  aim: "new" } })
          });

      }
      
    },
    editalert(id) {
      router.push({ name: 'alertedit', params: { alertId: id ,  aim: "edit" } })
    },
    deletealert(id, tc)  {
      db.collection('statistics').doc(accountUUID()).update("usedAlerts", this.users_alerts.length-1)
      db.collection('userbase').doc(accountUUID()).collection('alerts').doc(id).update({deleted: true, deletedate: Date.now()})
      console.log(tc)
      db.collection('publicalerts').doc(tc).update("deleted", true)

    },
    undeletealert(id, tc)  {
      var maxnumb = getAccountValues(this.userdb.account.accounttype, "maxAlarm")
      if (this.users_alerts.length >= maxnumb) {
        console.log("too much alerts")
        this.snackbartext = "Maximalzahl der Alarme im Vertrag "+getAccountValues(this.userdb.account.accounttype, "name")+" erreicht. Bitte ändern Sie den Vertrag in EINSTELLUNGEN"
        this.snackbarcolor = "error"
        this.snackbar = true
      }
      else {
        db.collection('statistics').doc(accountUUID()).update("usedAlerts", this.users_alerts.length+1)
        db.collection('userbase').doc(accountUUID()).collection('alerts').doc(id).update("deleted", false)
        db.collection('publicalerts').doc(tc).update("deleted", false)
      }
    },
    purgeOld(){
      var deletebefore30d = Date.now() - 1000 * 60 * 60 * 24 * 30 // älter als einen Monat
      this.users_alerts_del.forEach(function(item) {
        if (item.deletedate<deletebefore30d) {
          console.log("xxxx")
          db.collection('userbase').doc(accountUUID()).collection('alerts').doc(item.id).delete()
        }
      })
    },
    changedesign() {
      // TODO beim Wechsel von Test zu Small muss man owndesign abschalten
      var owndesignallowed = getAccountValues(this.userdb.account.accounttype, "owndesign")
      if (owndesignallowed) {
        router.push('/alertsdesign')
      }
      else {
        this.snackbartext = "Eigenes Alarmdesign im Vertrag "+getAccountValues(this.userdb.account.accounttype, "name")+" nicht möglich. Bitte ändern Sie den Vertrag in EINSTELLUNGEN"
        this.snackbarcolor = "error"
        this.snackbar = true

      }
    },

    getLabel(type) {
      return getTypeLabelFromType(type)
    },
    dateToStringFLEX(x) {
      return  dateToStringFLEX(x)
    },
    save() {
      console.log("save")
      db.collection('userbase').doc(accountUUID()).update("design", {})
      db.collection('userbase').doc(accountUUID()).update("design", this.userdb.design)
    },


  }
}

</script>
