
<!-- Material Icons https://iconify.design/icon-sets/mdi/ -->
<template>
  <v-app>
    <v-app-bar app color="orange" dense dark v-if="!nobar">
      
      <v-app-bar-nav-icon class="d-md-none" @click="drawer = !drawer"></v-app-bar-nav-icon>
      <div class="mauspointer" @click="clickLogo()" cursor="pointer" ><v-toolbar-title><b>crowdalerts.net</b></v-toolbar-title></div>
      <v-spacer></v-spacer>
      <div class="md-collapse d-none d-md-block" v-for="link in menulinks" :key="link.id" :id="link.id">
        <v-btn @click="$router.push(link.route)" target="_blank"  text v-show="checklogin(link.loggedinstatus) && link.route!='tour'">
          <span  class="mr-2">{{ link.text }}</span>
          <v-icon>{{ link.icon }}</v-icon>
        </v-btn>
        <v-btn @click="startTour()" target="_blank" id="v-step-0"  text v-show="checklogin(link.loggedinstatus) && link.route=='tour'">
          <span class="mr-2">{{ link.text }}</span>
          <v-icon>{{ link.icon }}</v-icon>
        </v-btn>
        
      </div>
    </v-app-bar>

<!-- Aktiviert vom Hamburger Button -->
    <v-navigation-drawer v-model="drawer" 
      absolute
      temporary color="orange">
      <v-list >
        <div v-for="link in menulinks" :key="link.id" v-show="checklogin(link.loggedinstatus)">
        <v-list-item  router :to="link.route" >
          <v-list-item-action>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title >{{ link.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        </div>
        <v-spacer>
        </v-spacer>
        <div v-for="flink in footerlinks" :key="flink.id">
        <v-list-item  router :to="flink.route" >
          <v-list-item-action>
            <v-icon>{{ flink.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title >{{ flink.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-main>
    <!-- Provides the application the proper gutter -->
      <v-container fluid>
      <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-footer id="tour1" class="md-collapse d-none d-md-block" color="orange" padless dense dark v-if="!nobar">
      <v-row justify="center" no-gutters>
        <v-btn v-for="link in footerlinks" :key="link.id" text class="mr-2" @click="$router.push(link.route)">{{ link.text }}</v-btn>
      </v-row>
    </v-footer>

    <v-tour name="myTourStart" :steps="tourstepsstart" :options="touroptions"></v-tour>
    <v-tour name="myTourLater" :steps="tourstepslater" :options="touroptions"></v-tour>

  </v-app>
</template>

<script>
import { accountUUID, isLoggedIn, accountName, user_logout } from "./user.js";
import { db } from "./firebase";

//import {accountName, user_logout } from "./user.js";
import router from "./router";

export default {
  name: "app",
  created(){
    let name = this.$route.name
    if (name == "scanlander") {
      this.nobar=true
    }
    
    console.log( name)
  },

  methods: {
    checklogin: function(loggedinstatus) {
      if (loggedinstatus=="true") {
        return isLoggedIn()
      }
      if (loggedinstatus=="false") {
        return !isLoggedIn()
      }
      if (loggedinstatus=="all") {
        return true
      }

      return false;
    },
    accountName: function() {
      return accountName();
    },
    logout: function() {
      user_logout();
      router.push('/home');
    },
    clickLogo() {
      if (accountUUID()=="ghBFQpIEXwclQ4xnqJZLBnsrCa52") {
        router.push('/admin')

      }
      else {
        router.push('/home')

      }

    },
    startTour() {
      // nur für menü, das erste Starttour wird in Home mounted gestartet
      if (this.tourstepslater.length==0) {
        this.tourstepsstart.forEach(element => {
          if (element.later == true) {
            this.tourstepslater.push(element)
          }
        });
      }
      this.$tours['myTourLater'].start()
    }

  },
  data: () => ({
      drawer: false,
      nobar: false,
      menulinks: [
      {index:1, icon: 'mdi-alarm-light', text: 'Alarme', route: '/alerts', loggedinstatus: 'true', id: "menu-alerts"},
      {index:2,  icon: 'mdi-account-arrow-left', text: 'Ziele', route: '/targets', loggedinstatus: 'true', id: "menu-targets"},
      {index:3,  icon: 'mdi-playlist-check', text: 'Listen', route: '/list', loggedinstatus: 'true', id: "menu-list"},
      {index:4,  icon: 'mdi-account-edit', text: 'Einstellungen', route: '/user', loggedinstatus: 'true', id:"menu-user"},
      {index:5,  icon: 'mdi-help-circle-outline ', text: 'tour', route: 'tour', loggedinstatus: 'true', id:"menu-tour"},
      {index:6,  icon: 'mdi-logout', text: 'Logout', route: '/logout', loggedinstatus: 'true'},
      {index:7,  icon: 'mdi-open-in-new', text: 'Registrieren', route: '/sign-up', loggedinstatus: 'false'},
      {index:8,  icon: 'mdi-open-in-new', text: 'Login', route: '/login', loggedinstatus: 'false'},
    ],
      footerlinks: [
      {index:1,  icon: '', text: 'Impressum', route: '/aboutus', },
      {index:2,  icon: '', text: 'Datenschutz', route: '/privacy'},
      {index:3,  icon: '', text: 'Nutzungsbedingungen', route: '/terms'},
    ],
    touroptions: {
          useKeyboardNavigation: false,
          labels: {
            buttonSkip: 'Tour Abbrechen',
            buttonPrevious: 'Zurück',
            buttonNext: 'Weiter',
            buttonStop: 'Beenden'
          }
        },
    tourstepslater: [],
    tourstepsstart: [
      {
        target: '#menu-tour',  // We're using document.querySelector() under the hood
        header: {
          title: 'Willkommen bei crowdalerts.net',
        },
        content: `Hier bei der Tour werden Ihnen die Funktionen kurz erklärt. Die Tour kann jederzeit oben im Menüpunkt Tour gestartet werden.`,
      },
      {
        target: '#menu-targets',  // We're using document.querySelector() under the hood
        header: {
          title: 'Die Ziele',
        },
        content: `Die Ziele, das sind die Adressen an die die Benachrichtigungen gesendet werden.`,
        later: true,
        before: type => new Promise((resolve, reject) => {
          router.push('/targets')
          resolve()
        })
      },
      {
        target: '#targets-add',  // We're using document.querySelector() under the hood
        header: {
          title: 'Ziele hinzufügen',
        },
        content: `Neue Ziele können hier hinzugefügt werden. Derzeit können Ziele vom Typ email, telegram Messenger und der crowdalerts.net Liste hinzugefügt werden.`,
        later: true,
        params: {
              placement: 'left', // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
              enableScrolling: false
            }
      },
      {
        target: '#targets-preset',  // We're using document.querySelector() under the hood
        content: `Ein erstes Ziel, ihre bei der Registrierung angegebene Emailadresse haben wir schon als Ziel angelegt.`
      },
      {
        target: '#targets-edit',  // We're using document.querySelector() under the hood
        header: {
          title: 'Ziele bearbeiten',
        },
        content: `Die Ziele können hier bearbeitet werden.`,
        later: true,
      },
      {
        target: '#menu-alerts',  // We're using document.querySelector() under the hood
        header: {
          title: 'Die Alarme',
        },
        content: `Alarme werden von den Personen vor Ort ausgelöst.`,
        later: true,
        before: type => new Promise((resolve, reject) => {
          router.push('/alerts')
          resolve()
        })
      },
      {
        target: '#alerts-add',  // We're using document.querySelector() under the hood
        header: {
          title: 'Alarm hinzufügen',
        },
        content: `Alarme könnnen hier hinzugefügt werden.`,
        later: true,
      },
      {
        target: '#alerts-preset',  // We're using document.querySelector() under the hood
        content: `Den ersten Alarm haben wir schon mal angelegt.`
      },
      {
        target: '#alerts-edit',  // We're using document.querySelector() under the hood
        header: {
          title: 'Alarme bearbeiten',
        },
        content: `Die Alarme können hier bearbeitet werden. Wir öffnen den obersten Alarm zum Bearbeiten`,
        later: true,
      },
      {
        target: '#alertedit-name',  // We're using document.querySelector() under the hood
        content: `Der Name des Alarms, wird vor allem zur internen Unterscheidung verwendet`,
        later: true,
        params: {
          enableScrolling: false
        },
        before: type => new Promise((resolve, reject) => {
          db.collection('userbase').doc(accountUUID()).collection('alerts').limit(1).get().then(querySnapshot => {
            const id = querySnapshot.docs[0].id;
      
            router.push({ name: 'alertedit', params: { alertId: id ,  aim: "edit" } })
            resolve()          
          })
        })
      },
      {
        target: '#alertedit-hinweis',  // We're using document.querySelector() under the hood
        content: `Hinweise für den Empfänger der Benachrichtigung. Diese Information sieht die benachrichtende Person nicht.`,
        later: true,
        params: {
          enableScrolling: false,
        },
      },
      {
        target: '#alertedit-immediate',  // We're using document.querySelector() under the hood
        content: `Sofort senden: Die Benachrichtigung wird sofort nach dem Öffnen des Links im QR Code gesendet, ohne weitere Bestätigung.`,
        later: true,
        params: {
          enableScrolling: false,
          placement: 'top'
        },
      },
      {
        target: '#alertedit-text',  // We're using document.querySelector() under the hood
        content: `Hinweise, die der scannenden Person vor dem Senden bzw. über den Eingabefeldern angezeigt werden`,
        later: true,
        params: {
          enableScrolling: false,
          placement: 'top'
        },
      },
      {
        target: '#alertedit-fields',  // We're using document.querySelector() under the hood
        content: `Zusätzlich können hier Eingabefelder hinzugefügt werden, damit die scannende Person noch Details angeben kann. Hier kann auch ein größeres Formular erstellt werden`,
        later: true,
        params: {
          enableScrolling: false,
          placement: 'top'
        },
      },
      {
        target: '#alertedit-thanks',  // We're using document.querySelector() under the hood
        content: `Dieser Text wird der benachritenden Person nach dem Senden angezeigt. `,
        later: true,
        params: {
          placement: 'top'
        },
      },
      {
        target: '#alertedit-targets',  // We're using document.querySelector() under the hood
        content: `Hier werden die Ziele, die benachrichtigt werden sollen, angegeben. Natürlich können mehrere Ziele benachrichtigt werden.`,
        later: true,
        params: {
          placement: 'top'
        },
      },
      {
        target: '#alertedit-preset',  // We're using document.querySelector() under the hood
        content: `Wir haben den Alarm schon mal mit dem ersten Ziel gekoppelt. `,
        params: {
          placement: 'left'
        },
      },
      {
        target: '#qrcanvas',  // We're using document.querySelector() under the hood
        content: `Das ist der Code, den der Benutzer scannen muss. Probieren Sie es gleich aus. `,
        later: true,
        params: {
          placement: 'right'
        },
      },
      {
        target: '#alertedit-sign',  // We're using document.querySelector() under the hood
        content: `Hier kann ein Hinweisschild generiert werden. Natürlich können Sie den Code auch kopieren und in ein eigenes Hinweisschild einfügen  `,
        later: true,
        params: {
          placement: 'left'
        },
      },
    ]
  }),
};
</script>

<style scoped>
  .mauspointer { cursor: pointer }
</style>
