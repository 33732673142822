
const contracts = require('../functions/contracts.json');
const constants = require('../functions/constants.json');


export function getConstant(parameter) {
  return constants[parameter]
}

export function getAccountValues(acctype, parameter) {
  var ret 
  contracts.acc.forEach(element => {
    if (element.id == acctype) {
      ret = element[parameter]
    }
  });
  return ret
}

export function getAccounts() {
  return contracts.acc
}


