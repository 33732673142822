var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{attrs:{"timeout":5000,"top":"","color":_vm.snackbarcolor},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.snackbartext)+" "),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.snackbar=false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Admin"),_c('v-icon',[_vm._v("mdi-alarm-light")])],1),_c('v-spacer')],1),_c('v-card',{attrs:{"elevation":"2","outlined":""}},[_c('v-card-title',[_vm._v(" Rechnungen")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.headersbills,"items":_vm.bills,"sort-by":"date","sort-desc":""},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateToStringDDMMYYYY(item.date))+" ")]}},{key:"item.billnumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.billnumber)+" "),(item.account.perpost)?_c('v-btn',{attrs:{"target":"_blank","text":""}},[_c('v-icon',[_vm._v("mdi-email-outline")])],1):_vm._e(),(!item.account.perpost)?_c('v-btn',{attrs:{"target":"_blank","text":""}},[_c('v-icon',[_vm._v("mdi-at")])],1):_vm._e()]}},{key:"item.sum",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.grosssum)+"€ ")]}},{key:"item.facts",fn:function(ref){
var item = ref.item;
return [(item.tobesent)?_c('div',[_vm._v("noch schicken!")]):_vm._e(),(!item.paid)?_c('div',[_vm._v("unbezahlt")]):_vm._e()]}},{key:"item.opt",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"target":"_blank","text":""},on:{"click":function($event){return _vm.downloadbill(item)}}},[_c('v-icon',[_vm._v("mdi-file-download-outline")])],1),(item.tobesent)?_c('v-btn',{attrs:{"target":"_blank","text":""},on:{"click":function($event){return _vm.setSent(item)}}},[_c('v-icon',[_vm._v("mdi-clipboard-check")])],1):_vm._e(),(!item.paid)?_c('v-btn',{attrs:{"target":"_blank","text":""},on:{"click":function($event){return _vm.setPaid(item)}}},[_c('v-icon',[_vm._v("mdi-cash-100")])],1):_vm._e()]}}],null,true)})],1)],1),_c('v-card',{attrs:{"elevation":"2","outlined":""}},[_c('v-card-title',[_vm._v(" Accounts")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.headersaccounts,"items":_vm.userbase,"sort-by":"paiduntil","sort-desc":""},scopedSlots:_vm._u([{key:"item.adress",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.account.adress1)),_c('br'),_vm._v(" "+_vm._s(item.account.adress2)),_c('br'),_vm._v(" "+_vm._s(item.account.adress3)),_c('br'),_vm._v(" "+_vm._s(item.account.adress4)+" ")]}},{key:"item.account",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.account.useruuid)+" KNR: "+_vm._s(item.account.knr)),_c('br'),_vm._v(" "+_vm._s(_vm.getAccVal(item.account.accounttype, 'name'))+" ["+_vm._s(_vm.getTer(item.account.period))+"]"),_c('br'),_vm._v(" seit "+_vm._s(_vm.dateToStringDDMMYYYY(item.account.created))),_c('br'),_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"item.paiduntil",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateToStringDDMMYYYY(item.account.paiduntil))+" ")]}},{key:"item.num",fn:function(ref){
var item = ref.item;
return [_vm._v(" Targets: "+_vm._s(_vm.getUsedTargets(item.id))+" / "+_vm._s(_vm.getAccVal(item.account.accounttype, 'maxTargets'))+" "),_c('br'),_vm._v(" Alerts: "+_vm._s(_vm.getUsedAlerts(item.id))+" / "+_vm._s(_vm.getAccVal(item.account.accounttype, 'maxAlarm'))+" "),_c('br'),_vm._v(" Noti this: "+_vm._s(_vm.getUsedNotis(item.id, false))+" / "+_vm._s(_vm.getAccVal(item.account.accounttype, 'maxNotifications'))+" "),_c('br'),_vm._v(" Noti last: "+_vm._s(_vm.getUsedNotis(item.id, true))+" / "+_vm._s(_vm.getAccVal(item.account.accounttype, 'maxNotifications'))+" "),_c('br')]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }