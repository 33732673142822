var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{attrs:{"timeout":5000,"top":"","color":_vm.snackbarcolor},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.snackbartext)+" "),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.snackbar=false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Benachrichtigungsziele"),_c('v-icon',[_vm._v("mdi-account-arrow-left")])],1),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","id":"targets-add","dark":""},on:{"click":function($event){return _vm.newtarget()}}},[_vm._v("Neues Benachrichtigungsziel")])],1),_c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.headers,"items":_vm.users_targets},scopedSlots:_vm._u([{key:"item.targettitle",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"id":"targets-preset"}},[_vm._v(" "+_vm._s(item.targettitle)+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(_vm.getIcon(item.type)))]),_vm._v(" "+_vm._s(_vm.getLabel(item.type))+" ")]}},{key:"item.adress",fn:function(ref){
var item = ref.item;
return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isVerified(item.type,item.adress, item.hash)),expression:"!isVerified(item.type,item.adress, item.hash)"}],staticClass:"red--text"},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-alert")]),_vm._v(" ZIEL NICHT BESTÄTIGT")],1),_vm._v(" "+_vm._s(item.adress)+" ")]}},{key:"item.opt",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"id":"targets-edit","target":"_blank","text":""},on:{"click":function($event){return _vm.edittarget(item.id)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"target":"_blank","text":""},on:{"click":function($event){return _vm.deletetarget(item.id)}}},[_c('v-icon',[_vm._v("mdi-trash-can")])],1),(item.type=='crowdalertslist')?_c('v-btn',{attrs:{"target":"_blank","text":""},on:{"click":function($event){return _vm.visittargetCrowdaleretsList(item.id)}}},[_c('v-icon',[_vm._v("mdi-forward")])],1):_vm._e()]}}],null,true)}),_c('br'),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.users_targets_del.length>0),expression:"users_targets_del.length>0"}]},[_vm._v(" Gelöschte Benachrichtigungsziele (werden nach 30 Tagen automatisch endgültig gelöscht) "),_c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.headers_del,"items":_vm.users_targets_del},scopedSlots:_vm._u([{key:"item.targettitle",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.targettitle)+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(_vm.getIcon(item.type)))]),_vm._v(" "+_vm._s(_vm.getLabel(item.type))+" ")]}},{key:"item.deletedate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateToStringFLEX(item.deletedate))+" ")]}},{key:"item.opt",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"target":"_blank","text":""},on:{"click":function($event){return _vm.undeletetarget(item.id)}}},[_c('v-icon',[_vm._v("mdi-undo-variant")])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }