<template>
  <div>    
  <v-snackbar v-model="snackbar" :timeout="5000" top :color=snackbarcolor>{{snackbartext}} <v-btn icon @click="snackbar=false"><v-icon>mdi-close</v-icon></v-btn></v-snackbar>
    <v-app-bar app :color="headlinebackgroundcolor" dense dark >
      <a :href="headlinelink" style="text-decoration: none">
        <v-toolbar-title ><b :style="headlinetextcolor">{{headlinetext}}</b></v-toolbar-title>
      </a>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-form v-show="!triggered" >
      <div class="text--primary">
          {{pa.alertinputtext}}
      </div>

      <div v-for="item in pa.inputfields" :key="item.id">
        
        <v-text-field v-if="item.mandatory" :rules="[rules.required]"  :label="item.text" prepend-icon="mdi-email-edit-outline" :type="item.type" v-model="item.answer" @change="validateFields()"></v-text-field>  
        <v-text-field v-else :label="item.text" prepend-icon="mdi-email-edit-outline" type="text" v-model="item.answer"></v-text-field>  
      </div>

      <v-toolbar flat color="white">
        <v-spacer></v-spacer>
        <v-btn v-if="sendbutton && sendbuttonenable" color="primary" dark class="ma-2" @click="send()">Absenden</v-btn>
        <v-btn v-if="sendbutton && !sendbuttonenable" color="grey" dark class="ma-2" @click="sendnotpossible()">Absenden</v-btn>
      </v-toolbar>
    </v-form>
  <div class="text--primary">
    {{usertext}}  
  </div>
  <v-row justify="center">
    <v-btn text class="mr-2" href="https://crowdalerts.net/">{{ "delivered by crowdalerts.net" }}</v-btn>
  </v-row>

</div>

</template>

<script>

import { db } from "../firebase";

export default {
  name: 'scanlander',
    data: () => ({
      snackbar: false,
      snackbartext: "",
      snackbarcolor: "",
      headlinetext: "",
      headlinelink: "",
      headlinetextcolor: "color:#FFFFFF",
      headlinebackgroundcolor: "#FFFFFF",
      usertext: "Bitte warten",
      triggered: false,
      pa:"",
      sendbutton: false,
      sendbuttonenable: false,
      rules: {
          required: value => !!value || 'Pflichtfeld, bitte ausfüllen',
          counter: value => value.length <= 20 || 'Max 20 characters',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
        },
  }),

  created(){
    this.nobar = true;
    db.collection('publicalerts').doc(this.$route.params.trigger).get().then((pa) => { 
      if (pa.data().deleted == true) {
        this.usertext = "Dieser Alarm wurde gelöscht"
      }
      else {
        db.collection('publicdesign').doc(pa.data().userid).get().then((pd) => {
          if (pd.data().design.owndesign) {
            this.headlinetext = pd.data().design.titletext
            this.headlinelink = pd.data().design.titlelink
            this.headlinebackgroundcolor = pd.data().design.backcolor
            this.headlinetextcolor = pd.data().design.titlecolorcss
          }
          else {
            () => {this.setDefaultDesign()}
          }
        })
        .catch(
          this.setDefaultDesign()
        ) 
        this.pa = pa.data()
        this.usertext =""
        if (pa.data().immediate) {
          this.send()
        }
        else {
          this.sendbutton = true
          this.validateFields()
        }
        
      }
    })
    .catch(() => {
        this.setDefaultDesign()
        this.usertext = "Fehler: Nicht gefunden"
    }
    )
  },
  methods: {
    send() {
      if (this.triggered == false) {
          this.triggered = true
          var d = new Date()
          var ltz=d.getTimezoneOffset()
          
          this.pa.targets.forEach(target => {
            db.collection('ztmp_triggeredtargets').add({targetid: target, alert: this.pa, date: Date.now(), localTimeZone: ltz })
          })

          this.usertext = this.pa.alertusertext
      }
    },
    sendnotpossible() {
      this.snackbartext = "Bitte Felder ausfüllen"
      this.snackbarcolor = "error"
      this.snackbar = true

    },
    setDefaultDesign() {
      this.headlinetext = "crowdalerts.net"
      this.headlinelink = "https://crowdalerts.net"
      this.headlinebackgroundcolor = "orange"
      this.headlinetextcolor = "color:white"

    },
    validateFields() {
      // TODO: schon zu beginn rot anzeigen
      var allgood = true
      this.pa.inputfields.forEach(infi => {
        if (infi.mandatory==true && infi.answer.length<1) {
          allgood = false
          return
        }
      })
      this.sendbuttonenable = allgood
      return

    },
  }
}

</script>
