<template>
  <v-container class="fill-height" fluid>
  <v-snackbar v-model="snackbar" :timeout="5000" top :color=snackbarcolor>{{snackbartext}} <v-btn icon @click="snackbar=false"><v-icon>mdi-close</v-icon></v-btn></v-snackbar>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat >
            <v-toolbar-title>Account anlegen</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                label="Login"
                name="login"
                prepend-icon="mdi-account"
                type="text"
                v-model="email"
              ></v-text-field>

              <v-text-field
                id="password"
                label="Password"
                name="password"
                prepend-icon="mdi-lock"
                type="password"
                v-model="password"
              ></v-text-field>
              <v-checkbox v-model="agbcheck" label="Nutzungsbedingungen akzeptieren">asdsd
              </v-checkbox>
              <router-link to="terms" target="_blank">
                Nutzungsbedingungen in neuem Tab öffen
              </router-link>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="agbcheck" color="primary" width="150" @click="signUp()">Registrieren</v-btn>
            <v-btn v-else color="grey" width="150" @click="noagbchecked()">Registrieren</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import { db } from "../firebase"
import { accountName } from "../user.js";
import { accountUUID } from "../user.js";
import { getConstant } from "../constants.js";
import { funhash } from "../helpers.js";
import router from '../router'

export default {
  name: 'signUp',
  data: () => ({
    snackbar: false,
    snackbartext: "",
    snackbarcolor: "",
    email: '',
    password: '',
    useruuid: '',
    agbcheck: false

  }),
  methods: {
    signUp() {
      var self = this;
      firebase.auth().createUserWithEmailAndPassword(this.email, this.password).then(
        function () {
            db.collection('userbase').doc(accountUUID()).set({
              useruuid: accountName(),
              account: {
                useruuid: accountName(), 
                knr: 0,
                created: Date.now(),
                paiduntil: Date.now()+86400000 * getConstant("testperiod"),
                accounttype: "TEST",
                accountnext: "NOTCHOOSEN",
                period: "NOTCHOOSEN",
                newsletter: true,
                perpost: false,
                adress1: "",
                adress2: "",
                adress3: "",
                adress4: "",
                agbversionaccepted: getConstant("agbversion")
              },
              design : {
                owndesign: false,
                titlecolor: "#FFFFFF",
                titlecolorcss: "color:#FFFFFF",
                titletext: "crowdalerts.net",
                titlelink: "https://crowdalerts.net/",
                backcolor: "#FF9800"
              }
            });
            self.snackbartext = "Your account has been created"
            self.snackbarcolor = "success"
            self.snackbar = true
            // ersten Target anlegen
            var hash = funhash("mail"+accountName())
            var firsttarget = accountUUID().substr(0,10)+"firsttarget"
            db.collection('userbase').doc(accountUUID()).collection('targets').doc(firsttarget).set({type: "mail", deleted: false, hash: hash, adress: accountName(), targettitle: "Eigene Emailadresse"})
            // ersten Alert anlegen
            db.collection('userbase').doc(accountUUID()).collection('alerts').add({userid : accountUUID(), deleted: false, inputfields:[], targets:[firsttarget], 
              alerttitle: "Der erste Alarm", alertinputtext: "Wollen sie den Zustand melden?", alerttargettext: "Werkzeug ist im Raum 234", alertusertext: "Danke fürs Benachrichtigen", immediate: false, signtext: "Scannen Sie bitte den Code um es zu melden. Vielen Dank", signtitle: "Nicht OK?"})

            db.collection('statistics').doc(accountUUID()).set({useruuid: accountName(), notcount:{}});

            router.push({ name: 'home', params: { starttour: "true" } })
            //router.push('home');

        },
        function (err) {
          if (err.code == "auth/invalid-email") {
              self.snackbartext = "Keine Emailadresse angeben"
          }
          else if (err.code == "auth/weak-password") {
              self.snackbartext = "Das Passwort muss mindestans 6 Zeichen lang sein"
          }
          else if (err.code == "auth/email-already-in-use") {
              self.snackbartext = "Dieser Account existiert schon"
          }
          else {
              self.snackbartext = "ERROR: " + err.message+ "\ncode: "+ err.code
              console.log(err.code)
          }
          self.snackbarcolor = "error"
          self.snackbar = true
        }
      )
    },
    noagbchecked() {
      this.snackbartext = "Bitte Nutzungsbedingungen akzeptieren"
      this.snackbarcolor = "error"
      this.snackbar = true

    }
  }
}
</script>

<style scoped>
.login {
    margin-top: 40px;
}
input {
    margin: 10px 0;
    width: 20%;
    padding: 15px;
}
button {
    margin-top: 10px;
    width: 10%;
    cursor: pointer;
}
span {
    display: block;
    margin-top: 2ßpx;
    font-size: 13px
}


</style>