var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{attrs:{"timeout":5000,"top":"","color":_vm.snackbarcolor},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.snackbartext)+" "),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.snackbar=false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-toolbar',{attrs:{"flat":"","color":"white","id":"v-step-2"}},[_c('v-toolbar-title',[_vm._v("Alarme"),_c('v-icon',[_vm._v("mdi-alarm-light")])],1),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","id":"alerts-add","dark":""},on:{"click":function($event){return _vm.newalert()}}},[_vm._v("Neuer Alarm")])],1),_c('v-card',{attrs:{"elevation":"2","outlined":""}},[_c('v-card-title',[_vm._v("Alarmdesign ")]),_c('v-card-text',[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","id":"alerts-add","dark":""},on:{"click":function($event){return _vm.changedesign()}}},[_vm._v("Alarmdesign ändern")])],1)],1),_c('br'),_c('v-card',{attrs:{"elevation":"2","outlined":""}},[_c('v-card-title',[_vm._v("Alarme ")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.headers,"items":_vm.users_alerts},scopedSlots:_vm._u([{key:"item.alerttitle",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"id":"alerts-preset"}},[_vm._v(_vm._s(item.alerttitle))])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.immediate)?_c('div',[_vm._v("SOFORT SENDEN")]):_vm._e(),(!item.immediate && item.inputfields.length==0)?_c('div',[_vm._v("NUR BUTTON")]):_vm._e(),(!item.immediate && item.inputfields.length>0)?_c('div',[_vm._v("Eingabefelder: "),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((item.inputfields),function(infi){return _c('tr',{key:infi.name},[_vm._v(" "+_vm._s(infi.name)+" ")])}),0)]},proxy:true}],null,true)})],1):_vm._e()]}},{key:"item.targets",fn:function(ref){
var item = ref.item;
return [_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((item.targets),function(tar){return _c('tr',{key:tar.name},[(_vm.targetexists(tar))?_c('div',[_c('v-icon',[_vm._v(_vm._s(_vm.gettargetAdressIcon(tar)))]),_vm._v(_vm._s(_vm.gettargetName(tar))+" "+_vm._s(_vm.gettargetAdress(tar))+" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isVerified(tar)),expression:"!isVerified(tar)"}],staticClass:"red--text"},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-alert")]),_vm._v("ZIEL NICHT BESTÄTIGT ")],1)],1):_vm._e()])}),0),_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.targets.length==0),expression:"item.targets.length==0"}],staticClass:"red--text"},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-alert")]),_vm._v(" KEIN ZIEL DEFINIERT ")],1)]},proxy:true}],null,true)})]}},{key:"item.opt",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"id":"alerts-edit","target":"_blank","text":""},on:{"click":function($event){return _vm.editalert(item.id)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"target":"_blank","text":""},on:{"click":function($event){return _vm.deletealert(item.id, item.triggercode)}}},[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}}],null,true)}),_c('br'),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.users_alerts_del.length>0),expression:"users_alerts_del.length>0"}]},[_vm._v(" Gelöschte Alarme (werden nach 30 Tagen automatisch endgültig gelöscht) "),_c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.headers_del,"items":_vm.users_alerts_del},scopedSlots:_vm._u([{key:"item.alerttitle",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.alerttitle)+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.immediate)?_c('div',[_vm._v(" SOFORT ")]):_vm._e(),(!item.immediate && item.inputfields.length==0)?_c('div',[_vm._v(" NUR BUTTON ")]):_vm._e(),(!item.immediate && item.inputfields.length>0)?_c('div',[_vm._v(" Eingabefelder: "),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((item.inputfields),function(infi){return _c('tr',{key:infi.name},[_vm._v(" "+_vm._s(infi.name)+" ")])}),0)]},proxy:true}],null,true)})],1):_vm._e()]}},{key:"item.deletedate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateToStringFLEX(item.deletedate))+" ")]}},{key:"item.opt",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"target":"_blank","text":""},on:{"click":function($event){return _vm.undeletealert(item.id, item.triggercode)}}},[_c('v-icon',[_vm._v("mdi-undo-variant")])],1)]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }