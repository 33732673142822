<template>
<div>
  <v-snackbar v-model="snackbar" :timeout="5000" top :color=snackbarcolor>{{snackbartext}} <v-btn icon @click="snackbar=false"><v-icon>mdi-close</v-icon></v-btn></v-snackbar>
  <v-toolbar flat color="white">
    <v-toolbar-title>Admin<v-icon>mdi-alarm-light</v-icon></v-toolbar-title>
    <v-spacer></v-spacer>
  </v-toolbar>

    <v-card elevation="2" outlined>
      <v-card-title> Rechnungen</v-card-title>
      <v-card-text>
        <v-data-table :headers="headersbills" :items="bills" class="elevation-3" sort-by="date" sort-desc>
          <template v-slot:[`item.date`]="{item}">
            {{dateToStringDDMMYYYY(item.date)}}
          </template>   
          <template v-slot:[`item.billnumber`]="{item}">
            {{item.billnumber}}
            <v-btn v-if="item.account.perpost" target="_blank" text ><v-icon>mdi-email-outline</v-icon></v-btn>
            <v-btn v-if="!item.account.perpost" target="_blank" text ><v-icon>mdi-at</v-icon></v-btn>

          </template>   
          <template v-slot:[`item.sum`]="{item}">
            {{item.grosssum}}€
          </template>   
          <template v-slot:[`item.facts`]="{item}">
            <div v-if="item.tobesent">noch schicken!</div>
            <div v-if="!item.paid">unbezahlt</div>
            
          </template>   
          <template v-slot:[`item.opt`]="{item}">
            <v-btn @click="downloadbill(item)" target="_blank" text ><v-icon>mdi-file-download-outline</v-icon></v-btn>
            <v-btn v-if="item.tobesent" @click="setSent(item)" target="_blank" text ><v-icon>mdi-clipboard-check</v-icon></v-btn>
            <v-btn v-if="!item.paid" @click="setPaid(item)" target="_blank" text ><v-icon>mdi-cash-100</v-icon></v-btn>
          </template>   
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-card elevation="2" outlined>
      <v-card-title> Accounts</v-card-title>
      <v-card-text>
        <v-data-table :headers="headersaccounts" :items="userbase" class="elevation-3" sort-by="paiduntil" sort-desc>
          <template v-slot:[`item.adress`]="{item}">
            {{item.account.adress1}}<br>
            {{item.account.adress2}}<br>
            {{item.account.adress3}}<br>
            {{item.account.adress4}}
          </template>   
          <template v-slot:[`item.account`]="{item}">
            {{item.account.useruuid}} KNR: {{item.account.knr}}<br>
            {{getAccVal(item.account.accounttype, 'name')}} [{{getTer(item.account.period)}}]<br>
            seit {{dateToStringDDMMYYYY(item.account.created)}}<br>
            {{item.id}}
            
          </template>   
          <template v-slot:[`item.paiduntil`]="{item}">
            {{dateToStringDDMMYYYY(item.account.paiduntil)}}
          </template>   
          <template v-slot:[`item.num`]="{item}">
            Targets: {{getUsedTargets(item.id)}} / {{getAccVal(item.account.accounttype, 'maxTargets')}} <br>
            Alerts: {{getUsedAlerts(item.id)}} / {{getAccVal(item.account.accounttype, 'maxAlarm')}} <br>
            Noti this: {{getUsedNotis(item.id, false)}} / {{getAccVal(item.account.accounttype, 'maxNotifications')}} <br>
            Noti last: {{getUsedNotis(item.id, true)}} / {{getAccVal(item.account.accounttype, 'maxNotifications')}} <br>
            
          </template>   
        </v-data-table>
      </v-card-text>
    </v-card>

</div>
</template>

<script>
import { db } from "../firebase";
import { interal_site_admin_check } from "../user.js";
import { dateToStringFLEX, countstring, getTerm } from "../helpers.js";
import { dateToStringDDMMYYYY } from "../helpers.js";
import { getTypeLabelFromType } from "../helpers.js";
import { getAccountValues  } from "../constants.js";

import Bill from "../pdfbill.js";

export default {
  name: 'admin',
  data: () => ({
    bills: [],
    userbase: [],
    statistics: [],
    snackbar: false,
    snackbartext: "",
    snackbarcolor: "",
    headersbills: [
      {text: 'Datum', value: 'date', align: 'left'},
      {text: 'Rechnungsnummer', value: 'billnumber', align: 'left'},
      {text: 'Summe', value: 'sum', align: 'left'},
      { text: '', value: 'facts', sortable: false },
      { text: '', value: 'opt', sortable: false }
    ],
    headersaccounts: [
      {text: 'Adresse', value: 'adress', align: 'left'},
      {text: 'Account', value: 'account', align: 'left'},
      { text: 'Vertrag bis', value: 'paiduntil' },
      { text: 'Anzahl', value: 'num', sortable: false }
    ],
  }),
  firestore: {
    bills: db.collection('bills'),
    userbase: db.collection('userbase'),
    statistics: db.collection('statistics')
  },
  components: {
//      QrcodeVue,
  },
  mounted(){
    this.$bind('bills',db.collection('bills'))
//    this.userdb = db.collection('userbase').doc(accountUUID())
    
  },
  created(){
    interal_site_admin_check()
  },
  methods: {

    getLabel(type) {
      return getTypeLabelFromType(type)
    },
    dateToStringFLEX(x) {
      return  dateToStringFLEX(x)
    },
    dateToStringDDMMYYYY(x) {
      return dateToStringDDMMYYYY(x)
    },
    downloadbill(billdata){
      console.log(billdata)

      this.bill = new Bill(billdata)
      var doc = this.bill.createpdf();
      doc.save("Rechnung_"+dateToStringDDMMYYYY(billdata.date)+".pdf")
    },

    setSent(billdata) {
      billdata.tobesent = false
      db.collection('bills').doc(billdata.id).update({tobesent: false}) 
    },
    setPaid(billdata) {
      billdata.paid = false
      db.collection('bills').doc(billdata.id).update({paid: true}) 
    },
    getUsedTargets(uid) {
      var rrr =""
        this.statistics.forEach(function(sitem) {
          if (sitem.id == uid) {
            rrr= sitem.usedTargets
          }
        })
      return rrr
    },
    getUsedAlerts(uid) {
      var rrr =""
        this.statistics.forEach(function(sitem) {
          if (sitem.id == uid) {
            rrr= sitem.usedAlerts
          }
        })
      return rrr
    },
    getUsedNotis(uid, last) {
      var rrr =""
      this.statistics.forEach(function(sitem) {
        if (sitem.id == uid) {
          rrr = sitem.notcount[countstring(last)]
        }
      })
      return rrr
    },
    getAccVal(x,y) {
      return getAccountValues(x,y)
    },
    getTer(x) {
      return getTerm(x)
    },


  }
}

</script>
