import firebase from "firebase/compat/app";
import 'firebase/compat/auth';

import router from './router';


export function isLoggedIn() {
  var user = firebase.auth().currentUser;
  if (user) {
    // User is signed in.
    return true;
  } else {
    // No user is signed in.
    return false;
  }
}

export function accountName() {
  var user = firebase.auth().currentUser;
  if (user == null) {
    return "null";
  }
  return user.email;
}

export function accountUUID() {
  var user = firebase.auth().currentUser;
  if (user == null) {
    return "null";
  }
  return user.uid;
}

export function user_logout() {
  firebase.auth().signOut().then(function () {
    // Sign-out successful.
  }, function () {
    // An error happened.
  });
}

export function interal_site_login_check() {
  if (!isLoggedIn()) {
    router.push('home')
  }
}

export function interal_site_admin_check() {
  // muss auch in den firestore.rules geändert werden!!!
  if (accountUUID() != "ghBFQpIEXwclQ4xnqJZLBnsrCa52") {
    router.push('home')
  }
}

export function resetPassword(email) {
  firebase.auth().sendPasswordResetEmail(email)

}


